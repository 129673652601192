@import "./../../../../Assets/scss/custom-variables.scss";

.modalDialog {
    max-width: 800px !important;
    width    : 90%;

    .modalContent {
        @include shadow-z-5();
        border-radius: $border-radius-large;
        border       : none;

        .card-signup {
            margin: 0;

            .modal-header {
                padding-top: 0;
            }
        }

        .formContainer {
            .modulesContainer {
                .labelGrp {
                    display: flex;

                    .label {
                        border       : 1px solid #ccc;
                        border-radius: 5px;
                        cursor       : pointer;
                        min-width    : 90px;
                        padding      : 5px;
                        margin       : 0 10px;
                        text-align   : center;

                        &.selected {
                            border-color    : transparent;
                            background-color: #e4e0e5;
                        }


                    }
                }
            }
        }
    }

    .modalTitle {
        color      : $newDark;
        font-weight: $bold;
        line-height: 1;
        font-size  : 30px;
    }

    // Modal header
    // Top section of the modal w/ title and dismiss
    .modal-header {
        border-bottom : none;
        padding-top   : 24px;
        padding-right : 24px;
        padding-bottom: 0;
        padding-left  : 24px;
    }

    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    .modalBody {
        padding-top   : 24px;
        padding-right : 24px;
        padding-bottom: 16px;
        padding-left  : 24px;
    }

    // Footer (for actions)
    .modal-footer {
        border-top: none;
        padding   : 24px;

        &.text-center {
            text-align: center;
        }

        button {
            margin       : 0;
            padding-left : 16px;
            padding-right: 16px;
            width        : auto;

            &.pull-left {
                padding-left : 5px;
                padding-right: 5px;
                position     : relative;
                left         : -5px;
            }
        }
    }

    .modalBody+.modal-footer {
        padding-top: 0;
    }
}

.modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
}

.modal {
    .modal-dialog {
        margin-top: 130px;
    }

    .modal-header .close {
        color: $gray-light;

        &:hover,
        &:focus {
            opacity: 1;
        }

        i {
            font-size: 16px;
        }
    }
}

@media (max-width: $deviceBreak) {
    .modalDialog {
        margin: 0 5%;
    }
}