@import "./../../Assets/scss/custom-variables.scss";

.revenue_box {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(196, 196, 196, 0.2);
    padding: 20px 20px;
}

.revenue_title {
    margin-top: 7px !important;
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #7A7A7A;
}

.revenue_value {
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 53px;
}