.reasonWrapper {
    border       : 2px solid #F37C7C;
    border-radius: 10px;
    padding      : 10px 30px;
}

.reasonTitle {
    color      : #F30000;
    font-size  : 24px;
    line-height: 50px;
}

.reasonText {
    color      : #7a7a7a;
    font-size  : 20px;
    line-height: 36px;
}

.ml15 {
    margin-left: 15px;
}

.ml40 {
    margin-left: 40px;
}