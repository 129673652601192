@import "../../../../Assets/scss/custom-variables.scss";

.iconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 20px;
  text-align: center;

  .icon {
    display: block;
    height: 100%;
    padding: 8px;
  }
}

.status {
  display: flex;
  flex-wrap: nowrap;
}
.Active {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}
.Inactive {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #dbdbdb;
  border-radius: 10%;
}