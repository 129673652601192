@import "./../../../Assets/scss/custom-variables.scss";
.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;
  .searchIcon {
    height: 100%;
    padding: 8px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;

  .element {
    margin : 0 10px;
  }
}
.title {
  font-size: 20px;
  color: #212721;
  font-style: normal;
  font-weight: 700;
  line-height: 2.2;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}

//below using
.status {
	display: flex;
	flex-wrap: nowrap;
  }
  
  .Active {
	display: block;
	padding: 10px;
	text-align: justify;
	background-color: #ddf0dd;
	margin-right: 20px;
	border-radius: 10%;
  }
  
  .Inactive {
	display: block;
	padding: 10px;
	text-align: justify;
	background-color: #dbdbdb;
	border-radius: 10%;
  }

.emptyImage {
  padding: 12px;
  background: #f2f3f5;
  border: 1.18881px solid #f2f3f5;
  border-radius: 6px;
  width: fit-content;

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 44px;
    color: #212721;
    opacity: 0.2;
  }
}
  

.ImageWapper {
  display: flex;
  justify-content: center;
  div {
    display: inline-block;
    position: relative;
  }
  img {
    max-height: 70px;
    width: 100%;
  }
}