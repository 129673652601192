@import "../../../Assets/scss/custom-variables.scss";
.PersonalInformationLabel {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #c6c6c6;
}
.PersonalInformationDetail {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #212721;
}
.OrderSummaryCard {
  background: #f2f3f5;
  border-radius: 10px;
  padding: 15px 50px;
  margin: 0;
  .OrderSummaryItem {
    .OrderSummaryDetail {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 44px;
      color: #212721;
    }
    .OrderSummaryLabel {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 44px;
      color: #7a7a7a;
    }
  }
}
.OrderHistoryWrapper {
  min-width: 340px;
}
.SeeAllLink {
  float: right;
  color: #cb998d;
  &:hover {
    color: #cb998d;
  }
}

.icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.petalRewardsFormRadioLabel {
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  margin-left: -8px !important;
  img {
    width: 10px;
  }
}
.ProducatImageWapper {
  display: inline-block;
  position: relative;
  img {
    height: 50px;
    width: 50px;
    margin-top: 10px;
  }
  .ProducatBadge {
    position: absolute;
    top: 0;
    right: -10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-family: "Lato";
    font-weight: 900;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
  }
  .ProducatBadgeRed {
    background: #f37c7c;
  }
  .ProducatBadgeGreen {
    background: #8fc897;
  }
  .ProducatBadgeGrey {
    background: #c6c6c6;
  }
}
