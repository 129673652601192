@font-face {
    font-family: 'icomoon';
    src        : url('./../fonts/icomoon.eot?cyt37i');
    src        : url('./../fonts/icomoon.eot?cyt37i#iefix') format('embedded-opentype'),
        url('./../fonts/icomoon.ttf?cyt37i') format('truetype'),
        url('./../fonts/icomoon.woff?cyt37i') format('woff'),
        url('./../fonts/icomoon.svg?cyt37i#icomoon') format('svg');
    font-weight : normal;
    font-style  : normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family   : 'icomoon' !important;
    speak         : never;
    font-style    : normal;
    font-weight   : normal;
    font-variant  : normal;
    text-transform: none;
    line-height   : 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-attachment:before {
    content: "\e900";
    color  : #c6c6c6;
}

.icon-bank:before {
    content: "\e901";
}

.icon-calendar:before {
    content: "\e902";
    color  : #c6c6c6;
}

.icon-cart:before {
    content: "\e903";
}

.icon-check-circle:before {
    content: "\e904";
    color  : #8fc897;
}

.icon-check:before {
    content: "\e905";
    color  : #7a7a7a;
}

.icon-clock:before {
    content: "\e906";
    color  : #c6c6c6;
}

.icon-close:before {
    content: "\e907";
}

.icon-commission-delivery:before {
    content: "\e908";
}

.icon-dollar:before {
    content: "\e909";
}

.icon-down-arrow-caret:before {
    content: "\e90a";
    color  : #c6c6c6;
}

.icon-down-arrow:before {
    content: "\e90b";
}

.icon-duplicate-order:before {
    content: "\e90c";
}

.icon-dustbin:before {
    content: "\e90d";
    color  : #f37c7c;
}

.icon-edit:before {
    content: "\e90e";
    color  : #c2c2c2;
}

.icon-folder:before {
    content: "\e90f";
    color  : #8a8989;
}

.icon-heart-fill:before {
    content: "\e910";
    color  : #c2c2c2;
}

.icon-heart:before {
    content: "\e911";
    color  : #c2c2c2;
}

.icon-horizontal-dots:before {
    content: "\e912";
    color  : #c6c6c6;
}

.icon-info-circle:before {
    content: "\e913";
    color  : #c6c6c6;
}

.icon-info-triangle:before {
    content: "\e914";
    color  : #f37c7c;
}

.icon-left-arrow:before {
    content: "\e915";
    color  : #c4c4c4;
}

.icon-mail:before {
    content: "\e916";
    color  : #c6c6c6;
}

.icon-minus:before {
    content: "\e917";
}

.icon-notes:before {
    content: "\e918";
    color  : #c6c6c6;
}

.icon-notification-warning:before {
    content: "\e919";
    color  : #c6c6c6;
}

.icon-notification:before {
    content: "\e91a";
    color  : #c2c2c2;
}

.icon-phone:before {
    content: "\e91b";
    color  : #c6c6c6;
}

.icon-plus .path1:before {
    content: "\e91c";
    color  : rgb(203, 153, 141);
}

.icon-plus .path2:before {
    content    : "\e91d";
    margin-left: -1em;
    color      : rgb(255, 255, 255);
}

.icon-pocket:before {
    content: "\e91e";
    color  : #c6c6c6;
}

.icon-profile-icon:before {
    content: "\e91f";
    color  : #c2c2c2;
}

.icon-raplicate:before {
    content: "\e920";
}

.icon-right-arrow:before {
    content: "\e921";
    color  : #c4c4c4;
}

.icon-search:before {
    content: "\e922";
}

.icon-snooze:before {
    content: "\e923";
    color  : #8a8989;
}

.icon-tag:before {
    content: "\e924";
}

.icon-ticket .path1:before {
    content: "\e925";
    color  : rgb(255, 255, 255);
}

.icon-ticket .path2:before {
    content    : "\e926";
    margin-left: -1.1669921875em;
    color      : rgb(0, 0, 0);
}

.icon-ticket .path3:before {
    content    : "\e927";
    margin-left: -1.1669921875em;
    color      : rgb(255, 255, 255);
}

.icon-up-arrow:before {
    content: "\e928";
}

.icon-updown:before {
    content: "\e929";
    color  : #cb998d;
}

.icon-users:before {
    content: "\e92a";
}