@import "../../../Assets/scss/custom-variables.scss";

.ProducatImageWapper {
  display: inline-block;
  position: relative;
  img {
    height: 50px;
    width: 50px;
    margin-top: 10px;
  }
  .ProducatBadge {
    position: absolute;
    top: 0;
    right: -10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-family: "Lato";
    font-weight: 900;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
  }
  .ProducatBadgeRed {
    background: #f37c7c;
  }
  .ProducatBadgeGreen {
    background: #8fc897;
  }
  .ProducatBadgeGrey {
    background: #c6c6c6;
  }  
}
.orderStatusSelect {
  min-width: 200px;
}
