@import "./../custom-variables.scss";
@import "custom-form-controls";

html {
  -webkit-text-size-adjust: none; /* Prevent font scaling in landscape */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-height: 100vh;
}

body {
  font-family: $fontFamily;
  background-color: transparent;
}

* {
  font-family: $fontFamily !important;
}

a,
a:hover,
a:focus,
button,
button:hover,
button:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
}

.content-login-wrapper {
  height: 100vh;
}

.link {
  color: $btnBg;
  cursor: pointer;
}

ul,
li,
ol,
li {
  margin: 0;
  // padding: 0;
  list-style: none;
}

.h1 {
  font-size: 45px;
  color: $primaryDark;
  font-weight: $bold;
}

h2 {
  color: $primaryDark;
  font-size: 45px;
  font-weight: $bold;
  margin: 0;
}

h3 {
  font-size: 24px;
  color: $primaryDark;
}

.bold {
  font-weight: $bold;
}

.text-normal {
  text-transform: none;
}

.small-heading {
  font-size: 18px;
}

// .form-control {

// &:focus,
// .bmd-form-group.is-focused {
//     // background-color: $lightGray1;
// }
// }

.loader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  z-index: 11111;
}

.edit-icon-btn,
.delete-icon-btn {
  font-size: 30px;

  &:hover {
    color: $primaryDark;
  }
}

.red {
  color: $red !important;
}

// .nav-link[data-toggle].collapsed:before {
//   content: " ▾";
// }
// .nav-link[data-toggle]:not(.collapsed):before {
//   content: " ▴";
// }

.btn {
  text-transform: none;
  line-height: 1.4;
}

.light-grey-btn {
  background-color: $light4;
  border: 1px solid $light4Border;
  color: $black;
  padding: 10px;
  font-size: 23px;

  &:hover {
    background-color: $mediumGray;
    color: $white;
  }
}
.secondary-btn {
  background-color: #c6c6c6;
  font-size: 20px;
  color: $white;
  padding: 10px 30px;
  border-radius: 5px;
  min-width: 120px;
}

.gray-btn {
  background-color: $btnBg;
  font-size: 20px;
  color: $white;
  padding: 7px 30px;
  border-radius: 5px;
  min-width: 120px;
  font-weight: bold;

  @include hover-focus-active {
    background-color: darken($btnBg, 5%);
    color: $white;
  }

  @include hover-focus {
    &:active {
      background-color: darken($btnBg, 5%);
      color: $white;
    }
  }
}

.pink-btn {
  background-color: $btnBg;
  font-size: 20px;
  color: $white;
  text-transform: capitalize;
  padding: 7px 20px;
  border-radius: 5px;
  min-width: 120px;
  font-weight: bold;

  @include hover-focus-active {
    background-color: darken($btnBg, 5%);
    color: $white;
  }

  @include hover-focus {
    &:active {
      background-color: darken($btnBg, 5%);
      color: $white;
    }
  }
}

.order-btn {
  background-color: transparent;
  padding: 0;
  margin: 0;
  box-shadow: none;
  transition: transform 0.2s;
}

.order-btn:focus,
.order-btn.focus,
.order-btn:hover {
  background: transparent;
  box-shadow: none;
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
}

.before-after {
  position: absolute;
  left: 0;
  top: 0;
  content: " ";
}

.input-info {
  font-size: 20px;
  color: $primaryDark;

  span {
    font-size: 14px;
    color: $newGray;
  }
}

/******************** Time Clock ********************/
.current-date {
  color: $btnBg;
  font-size: $subTitleSize;
  font-weight: $bold;
}

.clockin-out {
  width: 238px;
  height: 238px;
  border-radius: 150px;
  color: $white;
  background-color: $newClockColor;
  font-size: 40px;
  cursor: pointer;
  margin: 100px 0 80px 0;

  &::before {
    @extend .before-after;
    width: 290px;
    height: 290px;
    border-radius: 150px;
    background-color: $newClockColor;
    opacity: 0.2;
    left: -25px;
    top: -25px;
    z-index: -1;
  }
}

.in-out-label {
  font-size: 34px;
  color: $mediumGray;
}

.in-time,
.out-time {
  color: $darkGreen;
  font-size: 34px;
  font-weight: $bold;
}

.popup-inTime {
  @extend .in-time;
  font-size: 21px;
  text-align: left;
}

.popupLabel {
  font-size: 21px;
  color: $newDark;
}

.out-time {
  color: $outTimeRed;
}

.totalHrs {
  font-size: 24px;
  color: $btnBg;

  span {
    color: $darkGray;
  }
}

.td-actions {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Custom-SwitchBox {
  .MuiTypography-body1 {
    font-weight: 700;
  }
}
.Custom-SwitchBox-on {
  .MuiTypography-body1 {
    font-weight: 700;
    color: #cb998d;
  }
}
.Custom-SwitchBox-off {
  .MuiTypography-body1 {
    font-weight: 700;
    color: #aaaaaa;
  }
}
.Custom-Accordian {
  .MuiAccordionSummary-content {
    margin: 10px 0 0 !important;

    .Mui-expanded {
      margin: 10px 0 0 !important;
    }
  }
}

.RadioLabelButtonStyle {
  .MuiButtonBase-root {
    border-left: none !important;
    border-right: none !important;
  }
  span {
    font-size: 20px;
    margin-bottom: 0;
    padding: 27px 0 0 15px;
    color: $newDark !important;
  }
}
.RadioLabelButtonStyle.is-checked span {
  color: #cc998d !important;
}

@media (max-width: $tabletBreak) {
  .top-panel,
  .current-date {
    padding-left: 15px;
    padding-right: 15px;
  }

  .in-out-label,
  .in-time,
  .out-time {
    font-size: 18px;
  }
}

/******************** Time Clock Ends ********************/

.timepicker {
  @extend .popupLabel;
}

.table {
  thead {
    tr {
      th {
        color: $lightGray;
        font-size: 16px;
        font-weight: $bold;
        padding: 22px 10px;
        border-color: $tableBorder;

        &.no-border {
          border: none;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-color: $tableBorder;
        color: $newDark;
        font-size: 18px;
        padding: 22px 10px;

        &.no-border {
          border: none;
        }
      }
    }

    .total-row {
      td {
        font-weight: $bold;
      }
    }
  }
  .time-box-padding .rdtTime td{
    padding: 0px !important;
  }
}

.custom-pagination {
  .MuiPaginationItem-page {
    color: #7a7a7a;
  }

  .MuiPagination-ul li:first-child .MuiPaginationItem-page {
    color: #cb998d;
  }

  .MuiPagination-ul li:last-child .MuiPaginationItem-page {
    color: #cb998d;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #cb998d !important;
    color: #fff;
    font-weight: bold;
  }
}

.popup-info-text {
  font-size: 32px;
  color: $black;
  line-height: 48px;
  padding: 50px 0 60px;
}

.gray-check {
  .form-check-sign {
    .check {
      border-color: $lightGray1;
    }
  }

  .form-check-input {
    &:checked {
      ~ .form-check-sign {
        .check {
          background-color: $lightGray1;
        }
      }
    }
  }
}

.green-check {
  .form-check-sign {
    .check {
      border-color: $newClockColor;
    }
  }

  .form-check-input {
    &:checked {
      ~ .form-check-sign {
        .check {
          background-color: $newClockColor;
        }
      }
    }
  }
}

.completed-reminder {
  color: $newClockColor;
}

@media (max-width: $tabletBreak) {
  .light-grey-btn {
    font-size: 14px;
    padding: 5px;
  }
}

@media (max-width: $deviceBreak) {
  .top-panel {
    padding-top: 20px;

    h2 {
      font-size: 18px;
      padding-top: 2px;
    }

    .top-links {
      padding-top: 0;
    }
  }
}

.bmd-form-group [class^="bmd-label"].bmd-label-floating,
.bmd-form-group [class*=" bmd-label"].bmd-label-floating {
  left: 0;
}

.bmd-form-group {
  padding-top: 16px;
  margin-bottom: 16px;

  .bmd-label-floating {
    font-size: 1.25rem;
    top: 1.5rem;
  }

  &.is-focused,
  &.is-filled {
    .bmd-label-floating {
      font-size: 0.875rem;
      top: 0.125rem;
    }
  }

  .form-control {
    font-size: 20px;
    height: 44px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .checkbox,
  .radio,
  & {
    label {
      font-size: 14px;
    }
  }
}

.recform {
  .bmd-form-group {
    margin-bottom: 0 !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.pagination {
  margin: 30px 0;
  align-items: center;
  justify-content: flex-end;

  li {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
      color: $newGray;
      background: rgba(0, 0, 0, 0);
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      padding: 4px 2px;
      margin: 0 3px;
      outline: none;

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }

    &.selected a {
      background-color: $brand-primary;
      color: $white;
    }

    &.previous,
    &.next {
      appearance: none;
      display: block;
      border: 0;
      cursor: pointer;
      text-align: center;
      transition: all 0.3s ease;

      a {
        font-size: 24px;
        color: $brand-primary;

        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }

      &.disabled a {
        opacity: 0.38;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

fieldset[disabled][disabled] .form-control,
.form-control.disabled,
.form-control:disabled,
.form-control[disabled] {
  background-color: transparent;
  cursor: not-allowed;
  // background-image: linear-gradient(to top, #9c27b0 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}

.change-time-model input {
  width: 120px !important;
}

.rdtCounter .rdtCount {
  font-size: 14px;
}

.css-kdmjor-control {
  border-color: hsl(0, 0%, 80%) !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  box-shadow: none !important;

  &:hover {
    border-color: transparent !important;
    box-shadow: none !important;
  }
}

/******************** Order Tabs Start ********************/
.RadioButtonStyle {
  .MuiButtonBase-root {
    border-left: none !important;
    border-right: none !important;
  }
}
.order-container {
  .MuiTabs-root {
    //display: inline-flex;
  }

  .MuiTab-root {
    padding: 6px 15px;
    min-width: 1px;
  }

  .MuiTabs-scroller {
    padding-top: 5px;
    padding-left: 3px;
  }

  .MuiButtonBase-root {
    border-left: 2px solid $tableBorder;
    border-right: 2px solid $tableBorder;
  }

  .MuiTab-labelIcon {
    min-height: 50px;
  }

  .MuiTab-wrapper {
    font-size: 16px;
    font-weight: bold;
    color: $mediumGray;
    position: relative;
    z-index: 1;
    flex-direction: inherit;
    align-items: center;
    justify-content: space-between;

    > .link {
      order: 2;
      margin-bottom: 0 !important;
      margin-left: 30px;
    }
  }

  .MuiTab-wrapper a {
    position: absolute;
    margin: 0 !important;
    right: 0;
    z-index: 9;
  }

  .Mui-selected {
    box-shadow: 0 0 7px rgba(170, 170, 170, 0.24);
    border: 1px solid $tableBorder;
    border-top: none;
    border-bottom: transparent;

    .MuiTab-wrapper {
      color: $newDark;
    }
  }

  .MuiTabs-indicator {
    background: transparent;
  }

  .MuiBox-root {
    padding: 0;
  }
}

/******************** Order Tabs End ********************/

/******************** Auto complete Tabs Start ******************/
.auto-select {
  font-size: 20px;
  //height: 44px;
  //padding-top: 0.5rem;
  //padding-bottom: 0.5rem;
  .MuiFormControl-marginNormal {
    //margin-top: 0.2rem !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 1px solid $newGray;
    border-radius: 0;
    background: no-repeat center bottom, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    background-image: linear-gradient(
        to top,
        #9c27b0 2px,
        rgba(156, 39, 176, 0) 2px
      ),
      linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
    transition: background 0s ease-out;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6px;
  }
  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      // border-bottom: 1px solid  $purple;
      border-bottom: none;
      background-size: 100% 100%, 100% 100%;
      transition-duration: 0.3s;
    }
  }
  .MuiInputLabel-outlined {
    color: #aaaaaa;
    //left: -15px;
    font-size: 14px;
  }
  .Mui-focused {
    color: #aaaaaa !important;
    //left: -15px;
  }
}

/******************** Auto complete Tabs End ******************/

// Internal Notes Global Css
.InternalNotesAccordianStyleAccordian {
  .MuiAccordionSummary-root {
    .Mui-expanded {
      min-height: auto;
    }
  }
}

.wrapper {
  display: flex;
}

.rightPanel {
  padding: 0 20px;
  position: relative;
  flex-grow: 1;
}

.withoutLogin {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  position: absolute;
}

/* Custom scrollbar */
.rcs-custom-scroll .rcs-custom-scrollbar .rcs-inner-handle {
  background-color: rgba($brand-primary, 0.5);
}

.tabBorderDesign {
  border-bottom: 1px solid $tableBorder;
  flex-grow: 1;
}

.incressdecress {
  border: 1px solid $inputBorder;

  .MuiButton-root {
    font-size: 20px !important;
    font-weight: 900 !important;
    color: $newGray;
    border: none;
    transition: background-color 0.3s;

    + .MuiButton-root {
      border-left: 1px solid $inputBorder;
    }

    &.Mui-disabled {
      color: $lightGray1;
      border: none;
    }
  }
  .MuiButtonGroup-grouped {
    min-width: 36px;
  }
  .MuiButton-outlined {
    width: 36px;
    padding: 0;
  }
}

/*@media (min-width: $desktopBreak) {
  .rightPanel {
    width: calc(100% - 300px);
  }
}

@media (max-width: $tabletBreak) {
  .rightPanel {
    margin-left: 0;
    width: 100%;
  }
}*/

@include media-breakpoint-up(lg) {
  .small-heading {
    font-size: 20px;
  }

  .rightPanel {
    width: calc(100% - 260px);
  }
}

@include media-breakpoint-up(xl) {
  .rightPanel {
    width: calc(100% - 300px);
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: $desktopBreakM) {
  .table {
    thead {
      tr {
        th {
          //font-size: 18px;
        }
      }
    }
    tbody {
      tr {
        td {
          //font-size: 20px;
        }
      }
    }
  }

  .rightPanel {
    width: calc(100% - 325px);
    padding-left: 30px;
    padding-right: 30px;
  }
}
//  Input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#Menu {
  position: relative !important;
  width: 100% !important;
  .MuiDrawer-paper {
    height: auto !important;
    width: 100% !important;
  }
}
.store {
  padding-top:13px;
}
// .round {
//   // width: 100%;
//   .DayPicker-wrapper {
//     padding-bottom: 0;
//   }
//   .DayPicker-Month {
//     width: 100%;
//   }
//   .DayPicker-Day {
//     position: relative;
//     &:focus {
//       outline: none !important;
//     }
//     &::before {
//       content: "";
//       width: 100%;
//       height: 100%;
//       border-radius: 50%;
//       position: absolute;
//       transform: scale(0.65);
//       top: 0;
//       left: 0;
//       opacity: 0;
//       background-color: #e4e0e5;
//       z-index: -1;
//     }
//   }
//   .DayPicker-Day {
//     &:hover {
//       background-color: rgba(0, 0, 0, 0) !important;
//       &::before {
//         content: "";
//         opacity: 0.5;
//       }
//       @include media-breakpoint-down(sm) {
//         background-color: var(--primary) !important;
//         color: #fff !important;
//       }
//       // border-radius: 50% !important;
//     }
//     &:active,
//     &:focus {
//       @include media-breakpoint-down(sm) {
//         background-color: #fff !important;
//         color: #202721 !important;
//       }
//     }
//     &.DayPicker-Day--selected {
//       background-color: var(--primary) !important;
//       color: #fff !important;
//     }
//   }
// }
