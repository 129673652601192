@import "./../../Assets/scss/custom-variables.scss";

.productsWrapper {
  width: 100%;

  .title {
    font-size: 24px;
    color: $newDark;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }

  .searchIconWrapper {
    background: $btnBg;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;

    .searchIcon {
      height: 100%;
      padding: 8px;
    }
  }
}

.emptyImage {
  padding: 12px;
  background: #f2f3f5;
  border: 1.18881px solid #f2f3f5;
  border-radius: 6px;
  width: fit-content;

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 44px;
    color: #212721;
    opacity: 0.2;
  }
}
.quantityCol {
  min-width: 130px;
}

.quantityLabel {
  min-width: 12px;
}

.MuiButton-root {
  font-size: 20px !important;
  font-weight: 900 !important;
}
.checkBox {
  font-size: 17px;
  color: #131413;
}
.productTitle {
  font-size: 20px;
  color: $newDark;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}
.uploadBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e0e5;
  background: #f2f3f5;
  height: 100px;
  width: 140px;
  margin-top: 12px;
}
.imageLabel {
  margin-top: 50px;
  font-size: 16px;
  height: 44px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.status {
  display: flex;
  flex-wrap: nowrap;
  margin-top: -11px;
  .Active {
    background-color: #ddf0dd;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }
  .Inactive {
    background-color: #dbdbdb;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }
}
