@import "./../../../Assets/scss/custom-variables.scss";
.chart {
  & > svg {
    height: 484px;
    width: 968px;
    background-color: red;
    & > rect {
      //   x: 30;
      //   y: 183;
      fill: #212721;
    }
  }
}

.ChartFormRadio {
  display: flex;
  label {
    span:first-child {
      display: none;
    }
    span {
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.PieChartList {
  ul {
    margin-right: 20px;
    li {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.2;
      white-space: nowrap;
      padding: 4px;
      text-align: left;
      list-style: disc;
      span {
        font-weight: 700;
      }
    }
  }
  // background-color: red;
}

.fillColor {
  width: 100%;
  height: 10px;
  border-radius: 25px;
}
.progressBarTitle {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    color: #7a7a7a;
    text-align: left;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    color: #212721;
    text-align: right;
  }
}
