@import "../../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
    background: $btnBg;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .searchIcon {
      height: 100%;
      padding: 8px;
    }
  }

  .clearIconWrapper {
    background: $lightGray1;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .clearIcon {
      height: 100%;
      padding: 8px;
    }
  }