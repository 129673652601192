.paymentCardDetails {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #212721;
}

.paymentCardDetailsHeading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #c6c6c6;
}

.inputClass {
  border-bottom: 1px solid #212721;
  transition: all 0.3s ease;
  height: 46px;
  padding-left: 3px;
}
