@import "./../../Assets/scss/custom-variables.scss";

.loginWrapper {
    background     : url("./../../Assets/images/login.png") left top no-repeat;
    background-size: cover;
}

.loginBlock {
    max-width       : 600px;
    width           : calc(100% - 3%);
    margin          : auto;
    padding         : 40px;
    background-color: $white;
    box-shadow      : 0px 0px 10px rgba(196, 196, 196, 0.2);
    display         : block;
    border-radius   : 20px;
}