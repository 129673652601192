.searchIcon {
  cursor: pointer;
  margin : 0 0 0 20px;
}
.allowed {
  color : green;
  font-weight: 1000;
}
.blocked {
  color: red;
  font-weight: 1000;
}
.status {
  display: flex;
  flex-wrap: nowrap;
}

.Active {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}

.Inactive {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #dbdbdb;
  margin-right: 20px;
  border-radius: 10%;
}
.deliveryAddress {
  display: flex;
  flex-flow: row wrap;
  .deliveryContent {
    box-sizing: content-box;
    width: 15%;
    height: 25px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #c6c6c6;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .active {
    box-sizing: content-box;
    width: 15%;
    height: 25px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #c6c6c6;
    margin-bottom: 10px;
    margin-left: 10px;
    background-color: #e4e0e5;
  }
}
