/* PaymentComponent.module.css */

.embeddedComponentStyle {
    display: block;
    width: 100%;
    margin: 5px auto;
    border: none;
    border-radius: 15px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
}

.buttonStyle {
    background-color: #ffa300;
    border: none;
    padding: 10px 30px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 100px;
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    margin-left: auto;
    display: block;
    margin-top: 40px;
}