@font-face {
  font-family: "Kern";
  src: url("Assets/fonts/Kern-Bold.eot");
  src: url("Assets/fonts/Kern-Bold.eot?#iefix") format("embedded-opentype"),
    url("Assets/fonts/Kern-Bold.woff2") format("woff2"),
    url("Assets/fonts/Kern-Bold.woff") format("woff"),
    url("Assets/fonts/Kern-Bold.ttf") format("truetype"),
    url("Assets/fonts/Kern-Bold.svg#Kern-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kern";
  src: url("Assets/fonts/Kern-Regular.eot");
  src: url("Assets/fonts/Kern-Regular.eot?#iefix") format("embedded-opentype"),
    url("Assets/fonts/Kern-Regular.woff2") format("woff2"),
    url("Assets/fonts/Kern-Regular.woff") format("woff"),
    url("Assets/fonts/Kern-Regular.ttf") format("truetype"),
    url("Assets/fonts/Kern-Regular.svg#Kern-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --blush: #cb998d;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.react-tel-input .special-label {
  top: -10px !important;
}

.react-tel-input .form-control {
  padding: 24.5px 14px 24.5px 58px !important;
  width: 370px !important;
  border: none !important;
  border-bottom: 1px solid #CACACA !important;
  outline: none !important;
}

.react-tel-input .form-control:focus{
  border: none !important;
  border-bottom: 1px solid #000 !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.react-tel-input {
  margin: 12px !important;
  margin-left: 0 !important;
  color: "black" !important;
}
.css-1okebmr-indicatorSeparator{
  display: none;
}

.swt-alert-info div{
  border-color:#cb998d !important;
}
.swt-alert-info > div div{
  background-color:#cb998d !important;
}

.swt-alert-info .btn-info{
  background-color:#cb998d !important;
  border-color:#cb998d !important;
  box-shadow: none !important;
  color: #fff !important;
}

.swt-alert-info .btn-info:hover{
  background-color:#c3897b !important;
  border-color:#c3897b !important;
}
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }