@import "./../../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
    background    : $btnBg;
    height        : 34px;
    width         : 34px;
    display       : inline-block;
    vertical-align: middle;
    border-radius : 4px;
    text-align    : center;

    .searchIcon {
        height : 100%;
        padding: 8px;
    }
}

.title {
    font-size     : 30px;
    color         : $newGray;
    font-style    : normal;
    font-weight   : 700;
    line-height   : 32px;
    letter-spacing: 0px;
    text-align    : left;
    display       : inline-block;
    vertical-align: middle;
}