@import "./../../../Assets/scss/custom-variables.scss";
.card {
  background: #fff;
  position: relative;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 6px 0 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);
  .cardItem {
    
    margin-top: 20px;
    margin-bottom: 50px;
    border-bottom: 1px solid #f8f7f6;

    span {
      font-size: 44px;
      font-weight: $font-weight-normal;
      line-height: 1.2;
      color: #212721;
    }
    img {
      width: 40.5px;
      height: 43.34px;
    }
    p {
      font-size: 19px;
      font-weight: $font-weight-normal;
      line-height: 1.1;
      color: #7a7a7a;
    }
  }
  .PopularProductItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: $font-weight-normal;
    line-height: 2.73;
    color: #7a7a7a;
    p {
      margin-left: 15px;
      margin-right: 15px;
    }
    span {
      margin-bottom: 10px;
    }
  }
}

.digits{
  font-style: normal;
font-weight: normal;
font-size: 28px;
line-height: 53px;
/* identical to box height */
text-align: center;
color: #212721;
}

.PopularProductListImage{
    width : 84px;
    height : 91px;
}
.PopularProductList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: start;
  gap: 95px 20px;
  & .textDiv {
    margin-top: 20px;
    position :absolute;
    font-size: 14px;
line-height: 19px;
color: #212721;
    & span {
      margin-top: 10px;
      color: #212721;
      font-weight: $font-weight-normal;
      line-height: 1.2;
    }
    & .itemPrice {
      font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 19px;

/* new_grey3 */

color: #7A7A7A;
    }
  }
 
}
.DashboardFormRadio {
  display: flex;
  label {
    span:first-child {
      display: none;
    }
    span {
      font-size: 16px;
      white-space: nowrap;
    }
  }
}
.iconDesign {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;
  margin-top: 25px;
  margin-left: 20px;
  img {
    display: block;
    height: 100%;
    padding: 8px;
  }
}

.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

.searchIcon {
    height: 100%;
    padding: 8px;
  }
}

.export{
  padding-left: 150px !important;
}

.clearIconWrapper {
  background: $lightGray1;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

.clearIcon {
    height: 100%;
    padding: 8px;
  }
}

.emptyImage {
  padding: 23px;
  background: #f2f3f5;
  border: 1.18881px solid #f2f3f5;
  width: fit-content;
  span {
    font-weight: normal;
    font-size: 10px;
    line-height: 44px;
    color: #212721;
    opacity: 0.4;
  }
}