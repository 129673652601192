@import "../../../Assets/scss/custom-variables.scss";

.DesignerStatusCard {
  background: $tableBorder;
  border-radius: 10px;
  padding: 15px 50px;
  margin: 0 0 40px;

  .DesignerStatusItem {
    .DesignerStatusDetail {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 44px;
      color: $newDark;
    }
    .DesignerStatusLabel {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 44px;
      color: $newGray;
    }
  }
}
.rt-filter-wapper {
  min-height: 0px;
}
.ProducatImageWapper {
  display: inline-block;
  position: relative;
  margin-left: 5px;

  img {
    height: 36px;
    width: 36px;
    margin-top: 5px;
  }
  .ProducatBadge {
    position: absolute;
    top: 0;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-weight: 900;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
  .ProducatBadgeRed {
    background: $outTimeRed;
  }
  .ProducatBadgeGreen {
    background: $darkGreen;
  }
  .ProducatBadgeGrey {
    background: $lightGray1;
  }
}

.imgLabel {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $newGray;
  margin-left: 5px;
}

.actionButton {
  // float: right !important;
  // margin: 0 !important;
  padding: 2px !important;
  font-size: 12px !important;
}

.actionButtonCommon {
  // float: right !important;
  // margin: 0 !important;
  padding: 3px !important;
  font-size: 14px !important;
  margin-top: 11px !important;
}
