.testimonials-1{
    .card-description{
        margin-bottom: 50px;
    }
}

.testimonials-2 .carousel{
    .carousel-inner .carousel-item{
        .card{
            max-width: 650px;
            margin: 60px auto;
        }
    }
}
