@import "./../../../Assets/scss/custom-variables.scss";

.selectMenu div ul {
    padding         : 5px 0;
    border          : 0;
    margin          : 0;
    box-shadow      : none;
    min-width       : 100%;
    border-radius   : 4px;
    box-sizing      : border-box;
    display         : block;
    font-size       : 14px;
    text-align      : left;
    list-style      : none;
    background-color: #fff;
    background-clip : padding-box;
}

.selectMenu selectPaper selectMenuItemSelectedMultiple {
    background-color: inherit;
}

.selectMenu div+div {
    max-height: 266px !important;
}

.select {
    padding           : 12px 0 7px;
    font-size         : .75rem;
    font-weight       : 400;
    line-height       : 1.42857;
    text-decoration   : none;
    // text-transform : uppercase;
    color             : $newDark;
}

.select:focus {
    background-color: transparent !important;
}

.select[aria-owns]+input+svg {
    transform: rotate(180deg);
}

.select+input+svg {
    transition: all 300ms linear;
}

.selectMenuItem {
    font-size    : 13px;
    padding      : 10px 30px 10px 20px;
    margin       : 0 5px;
    border-radius: 2px;
    transition   : all 150ms linear;
    display      : block;
    clear        : both;
    font-weight  : 400;
    line-height  : 2;
    white-space  : nowrap;
    color        : $newDark;

    &:hover {
        background-color: $brand-primary !important;
        color           : $white;
        box-shadow      : $boxShadow;
    }
}

.selectMenuItemSelected {
    background-color: $brand-primary !important;
    color           : $white !important;
}

.selectLabel {
    font-size        : 12px;
    // text-transform: uppercase;
    //color            : $lightGray1 !important;
}

.errorInput label {
    color: #f44336 !important;
}

.errorInput input:focus {
    background-image: linear-gradient(to top, #f44336 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
