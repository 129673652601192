@import "../../../../../Assets/scss/custom-variables.scss";

.cardBlock,
.cardBlockSaved {
  padding: 30px;
  max-width: 440px;
  border-radius: 18px;
  border: 1px solid #e8e8e8;

  .cardTitle {
    color: #c4c4c4;
    font-size: 18px;
  }

  .formControl {
    border-radius: 0;
    border: none;
    padding: 15px 0;
    color: #a4aaaf;
    font-weight: 300;
  }

  .saveCard {
    right: 30px;
    top: 22px;
    border: 1px solid #cb998d;
    padding: 3px 5px 1px;
    font-size: 12px;
  }
}

.cardBlockSaved {
  // box-shadow   : 0px 0px 4px rgb(0 0 0 / 10%);
  border: none;
  padding: 5px 30px;

  max-width: 350px;
  border-radius: 0;
  &:hover {
    background: #fcfaf9;
  }
  .savedCardInfo {
    font-weight: 300;
    color: #7d858c;

    img {
      line-height: 50px;
    }

    span {
      font-size: 18px;
      line-height: 30px;
      padding-right: 10px;
    }

    .cardNumberLast {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.cardExpiryDate {
  font-size: 14px;
  line-height: 30px;
  font-weight: 300;
  color: #7d858c;
}

.CardInput {
  // border: 1px solid #202721;
  // transition: all 0.3s ease;
  // height: 46px;
  // padding-left: 25px;

  height: 32px;
  margin-bottom: 1em;
  display: block;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative;
  &.focused {
    border-bottom: 2px solid #9c27b0;
  }

  &.error {
    border-bottom: 2px solid #f44336 !important;
  }

  &.success {
    border-color: #69ba78 !important;
    color: #69ba78 !important;
  }
}
