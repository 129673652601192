@import "../../Assets/scss/custom-variables.scss";

.productPopup {
  .popupRoot {
    border-radius: 0;
  }
}

.variantWrapper {
  background-color: transparent !important;
  position: absolute !important;
}

.popoverContent {
  background-color: rgba($brand-primary, 0.95);
  box-shadow: 0 0 10px 0 rgba($lightGray, 0.4);
  height: 100%;
  align-items: center;
  display: flex;
  height: max-content;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
  margin-bottom: 90px;

  @include media-breakpoint-up(xl) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.dropDownDrawer {
  margin: 10px 0 0 !important;
  box-shadow: 0px 8px 0px -1px rgba(0, 0, 0, 0),
    0px 24px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0) !important;
  position: absolute !important;
  max-height: max-content !important;
  // height: max-content;
  overflow: hidden;
  .list {
    padding: 20px;
    .productMenuChild {
      margin: 0 10px;
      .listTitle {
        color: $newDark;
        font-weight: 700;
      }
      .productMenuChildLink {
        margin: 5px 0;
        color: $newGray;
        width: max-content;

        &:hover {
          cursor: pointer;
          color: $btnBg;
        }
      }
    }

    .colors {
      margin: 0 10px;
      .listTitle {
        color: $newDark;
        font-weight: 700;
      }
      .section {
        display: flex;
        align-items: center;

        .colorCode {
          height: 20px;
          width: 20px;
          border-radius: 50%;
          margin: 5px 0 0;

          &.border {
            border: 1px solid;
          }
        }

        .colorLink {
          margin: 5px 0 0 10px;
          color: $newGray;

          &:hover {
            cursor: pointer;
            color: $btnBg;
          }
        }
      }
    }

    .drawerImage {
      width: 300px;
      height: 300px;
      max-width: 300px;
      @media (max-width: 960px) {
        width: 300px;
        height: 200px;
        max-width: 300px;
      }
      @media (max-width: 600px) {
        width: 200px;
        height: 100px;
        max-width: 200px;
      }
    }
  }
}

.drawerWrapper {
  height: 100% !important;
  overflow-y: hidden !important;

  .drawerInner {
    min-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  //   margin: 0 0 40px;
  .closeIconWrapper {
    margin-top: 15px;
    margin-right: 15px;
    padding: 5px;

    @include media-breakpoint-up(xl) {
      margin-top: 23px;
      margin-right: 23px;
    }
  }

  .closeIcon {
    height: 25px;
    width: 25px;
  }

  .header {
    z-index: 50 !important;

    @include media-breakpoint-up(xl) {
      padding-left: 40px;
      padding-right: 40px;
    }

    .top {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      position: relative;
      height: max-content;
      //   width: max-content;

      @media (max-width: 1278px) {
        padding: 0 50px;
      }

      @media (max-width: 960px) {
        padding: 0 50px;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }

    .flowerList {
      height: 200px;
    }
  }

  .container {
    margin: 30px 0 0;

    @include media-breakpoint-up(xl) {
      margin-top: 50px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .topSection {
      padding: 10px 20px;
      display: flex;
      background-color: $tableBorder;
      border-radius: 7px;
      align-items: center;
      flex-wrap: wrap;

      @include media-breakpoint-up(xl) {
        padding: 15px 30px;
      }

      .parent,
      .child {
        font-size: 1.875rem;
        font-weight: 700;
        color: $newDark;

        @media (max-width: 1278px) {
          font-size: 1.5rem;
        }

        @media (max-width: 960px) {
          margin: 0 10px 0 0;
          font-size: 18px;
        }

        @media (max-width: 600px) {
          font-size: 16px;
        }
      }

      .arrow {
        transform: rotate(270deg);
        margin: 0 30px;

        @media (max-width: 1278px) {
          margin: 0 20px;
        }

        @media (max-width: 960px) {
          margin: 0 15px;
        }

        @media (max-width: 600px) {
          margin: 0 10px;
        }
      }

      .search {
        margin: 0 20px 0 auto;

        .root {
          border-bottom: 1px solid $lightGray1;
          color: $newDark;
          padding: 8px 0 3px;
          transition: 0.3s;
          font-size: 16px;

          &:focus {
            border-color: $lightGray1;
          }
        }

        .adornedEnd {
          height: 18px;
          width: 18px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          background-image: url("../../Assets/images/productSearch.svg");
        }
      }

      .sortBy,
      .price {
        font-size: 18px;
        font-weight: 700;
        margin: 0 10px;
      }

      .sortBy {
        color: $newGray;
      }
    }
  }

  .listContainer {
    padding-bottom: 90px;

    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  .containerPadding {
    padding-bottom: 150px;
  }

  .rowSection {
    position: relative;
  }

  .productAddBtnSection {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 0 10px rgba($lightGray, 0.5);
    z-index: 1400;
  }
}

.colSection {
  transition: all 0.3s ease;
  //min-width: 300px;
  margin-top: 1.5625rem;

  @media (max-width: 1278px) {
    min-width: 250px;
  }

  @media (max-width: 600px) {
    min-width: 200px;
  }

  &:hover {
    cursor: pointer;
  }

  .card {
    position: relative;
    width: 100%;
    //height: 20.625rem;
    padding-top: 115%;
    background-color: #f1f1f1;

    &:hover {
      box-shadow: 0px 0px 10px rgba(196, 196, 196, 0.5);
      transform: translateY(-2px);
    }

    .image {
      //max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }

    .price {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 5px 10px;
      font-size: 1.125rem;
      font-weight: 700;
      color: $newGray;
      background-color: rgba($white, 0.7);
    }
  }

  .name {
    font-size: 1.25rem;
    font-weight: 400;
    color: $newDark;
    margin-top: 10px;
    flex-grow: 1;
    max-height: 48px;
    overflow: hidden;
  }

  .cardBottom {
    display: flex;
    margin: 10px 0 1em;
    justify-content: space-between;
    flex-shrink: 0;

    .category {
      font-size: 18px;
      color: #7a7a7a;
    }

    .commission {
      font-size: 18px;
      font-weight: 700;
      color: #7a7a7a;
    }
  }
}

.card {
  margin: 20px 10px;
  background-color: #fff;
  padding: 10px;
  width: 14.4375rem;
  // height: 22rem;
  cursor: pointer;

  .image {
    width: 100%;
    height: 16rem;
  }

  .name {
    font-size: 18px;
    font-weight: 400;
  }

  .textSection {
    display: flex;
    margin: 10px 0 10px;
    align-items: center;

    .comm {
      font-size: 16px;
      font-weight: 700;
    }

    .sold {
      margin: 0 0 0 auto;
      font-size: 16px;
      font-weight: 700;
    }

    .soldIcon {
      height: 20px;
      width: 20px;
      border: 1px solid #c6c6c6;
      margin: 0 0 0 5px;
    }
  }

  .actions {
    display: flex;
    align-items: center;

    .price {
      font-size: 18px;
      color: #8a8989;
    }

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #c6c6c6;
      margin: 0 0 0 auto;
    }

    .circlechecked {
      color: #c6c6c6;
      width: 25px;
      margin-left: auto;
      height: 25px;
    }
  }
}
// @import "../../Assets/scss/custom-variables.scss";

// .productPopup {
//   width: 100%;

//   .popupRoot {
//     margin-top: -100px;
//     bottom: 100px;
//     left: 16px;
//     right: 16px;
//     width: calc(100vw - 32px);
//     border-radius: 0;
//     background-color: rgba($brand-primary, 0.95);
//     box-shadow: 0 0 10px 0 rgba($lightGray, 0.4);
//   }

//   .popoverContent {
//     display: flex;
//     height: max-content;
//     justify-content: center;
//     padding: 50px 20px;
//     flex-wrap: wrap;

//     .card {
//       margin: 20px 10px;
//       background-color: #fff;
//       padding: 10px;
//       width: 14.4375rem;
//       // height: 22rem;
//       cursor: pointer;

//       .image {
//         width: 100%;
//         height: 16rem;
//       }

//       .name {
//         font-size: 18px;
//         font-weight: 400;
//       }

//       .textSection {
//         display: flex;
//         margin: 10px 0 10px;
//         align-items: center;

//         .comm {
//           font-size: 16px;
//           font-weight: 700;
//         }

//         .sold {
//           margin: 0 0 0 auto;
//           font-size: 16px;
//           font-weight: 700;
//         }

//         .soldIcon {
//           height: 20px;
//           width: 20px;
//           border: 1px solid #c6c6c6;
//           margin: 0 0 0 5px;
//         }
//       }

//       .actions {
//         display: flex;
//         align-items: center;

//         .price {
//           font-size: 18px;
//           color: #8a8989;
//         }

//         .circle {
//           width: 20px;
//           height: 20px;
//           border-radius: 50%;
//           border: 1px solid #c6c6c6;
//           margin: 0 0 0 auto;
//         }

//         .circlechecked {
//           color: #c6c6c6;
//           width: 25px;
//           margin-left: auto;
//           height: 25px;
//         }
//       }
//     }
//   }
// }

// .dropDownDrawer {
//   margin: 50px 0 0 !important;
//   box-shadow: 0px 8px 0px -1px rgba(0, 0, 0, 0),
//     0px 24px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0) !important;
//   z-index: 2 !important;
//   position: absolute !important;
//   max-height: max-content !important;
//   overflow: hidden;

//   .list {
//     padding: 20px;
//     .productMenuChild {
//       margin: 0 10px;
//       .listTitle {
//         color: $newDark;
//         font-weight: 700;
//       }
//       .productMenuChildLink {
//         margin: 5px 0;
//         color: $newGray;
//         width: max-content;

//         &:hover {
//           cursor: pointer;
//           color: $btnBg;
//         }
//       }
//     }

//     .colors {
//       margin: 0 10px;
//       .listTitle {
//         color: $newDark;
//         font-weight: 700;
//       }
//       .section {
//         display: flex;
//         align-items: center;

//         .colorCode {
//           height: 20px;
//           width: 20px;
//           border-radius: 50%;
//           margin: 5px 0 0;

//           &.border {
//             border: 1px solid;
//           }
//         }

//         .colorLink {
//           margin: 5px 0 0 10px;
//           color: $newGray;

//           &:hover {
//             cursor: pointer;
//             color: $btnBg;
//           }
//         }
//       }
//     }

//     .drawerImage {
//       width: 300px;
//       height: 300px;
//       max-width: 300px;
//       @media (max-width: 960px) {
//         width: 300px;
//         height: 200px;
//         max-width: 300px;
//       }
//       @media (max-width: 600px) {
//         width: 200px;
//         height: 100px;
//         max-width: 200px;
//       }
//     }
//   }
// }

// .drawerWrapper {
//   height: 100% !important;

//   //   margin: 0 0 40px;
//   .closeIcon {
//     height: 25px;
//     width: 25px;
//     margin: 20px 20px 0 auto;

//     @include media-breakpoint-up(xl) {
//       margin-top: 28px;
//       margin-right: 28px;
//     }

//     &:hover {
//       cursor: pointer;
//     }
//   }

//   .header {
//     z-index: 50 !important;

//     @include media-breakpoint-up(xl) {
//       padding-left: 40px;
//       padding-right: 40px;
//     }

//     .title {
//       font-size: 45px;
//       color: $newDark;
//       margin: 1rem auto 3.75rem;
//       font-weight: 700;
//       text-align: center;

//       @media (max-width: 1278px) {
//         font-size: 30px;
//       }

//       @media (max-width: 960px) {
//         font-size: 24px;
//       }

//       @media (max-width: 600px) {
//         font-size: 20px;
//       }
//     }

//     .top {
//       display: flex;
//       flex-wrap: wrap;
//       padding: 0 15px;
//       position: relative;
//       height: max-content;
//       //   width: max-content;

//       @media (max-width: 1278px) {
//         padding: 0 50px;
//       }

//       @media (max-width: 960px) {
//         padding: 0 50px;
//         flex-wrap: wrap;
//         justify-content: flex-start;
//       }

//       .link {
//         font-size: 1rem;
//         font-weight: 700;
//         color: $newDark;
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         margin: 10px;

//         &.activeLink {
//           color: $btnBg;
//         }

//         @media (max-width: 960px) {
//           margin: 0 10px 0 0;
//         }

//         .dropDownArrow {
//           height: 10px;
//           width: 10px;
//           margin: 0 10px;
//         }
//       }
//     }

//     .flowerList {
//       height: 200px;
//     }
//   }

//   .container {
//     margin: 30px 0 0;

//     @include media-breakpoint-up(xl) {
//       margin-top: 50px;
//       padding-left: 40px;
//       padding-right: 40px;
//     }

//     .topSection {
//       padding: 10px 20px;
//       display: flex;
//       background-color: $tableBorder;
//       border-radius: 7px;
//       align-items: center;
//       flex-wrap: wrap;

//       @include media-breakpoint-up(xl) {
//         padding: 15px 30px;
//       }

//       .parent,
//       .child {
//         font-size: 1.875rem;
//         font-weight: 700;
//         color: $newDark;

//         @media (max-width: 1278px) {
//           font-size: 1.5rem;
//         }

//         @media (max-width: 960px) {
//           margin: 0 10px 0 0;
//           font-size: 18px;
//         }

//         @media (max-width: 600px) {
//           font-size: 16px;
//         }
//       }

//       .arrow {
//         transform: rotate(270deg);
//         margin: 0 30px;

//         @media (max-width: 1278px) {
//           margin: 0 20px;
//         }

//         @media (max-width: 960px) {
//           margin: 0 15px;
//         }

//         @media (max-width: 600px) {
//           margin: 0 10px;
//         }
//       }

//       .search {
//         margin: 0 20px 0 auto;

//         .root {
//           border-bottom: 1px solid $lightGray1;
//           color: $newDark;
//           padding: 8px 0 3px;
//           transition: 0.3s;
//           font-size: 16px;

//           &:focus {
//             border-color: $lightGray1;
//           }
//         }

//         .adornedEnd {
//           height: 18px;
//           width: 18px;
//           background-position: center;
//           background-repeat: no-repeat;
//           background-size: 100%;
//           background-image: url("../../Assets/images/productSearch.svg");
//         }
//       }

//       .sortBy,
//       .price {
//         font-size: 18px;
//         font-weight: 700;
//         margin: 0 10px;
//       }

//       .sortBy {
//         color: $newGray;
//       }
//     }
//   }

//   .listContainer {
//     padding-bottom: 90px;

//     @include media-breakpoint-up(xl) {
//       padding-bottom: 90px;
//       padding-left: 35px;
//       padding-right: 35px;
//     }
//   }

//   .rowSection {
//     .colSection {
//       transition: all 0.3s ease;
//       //min-width: 300px;
//       margin-top: 1.5625rem;

//       @media (max-width: 1278px) {
//         min-width: 250px;
//       }

//       @media (max-width: 600px) {
//         min-width: 200px;
//       }

//       &:hover {
//         cursor: pointer;
//       }

//       .card {
//         position: relative;
//         width: 100%;
//         //height: 20.625rem;
//         padding-top: 115%;
//         background-color: #f1f1f1;

//         &:hover {
//           box-shadow: 0px 0px 10px rgba(196, 196, 196, 0.5);
//           transform: translateY(-2px);
//         }

//         .image {
//           //max-width: 100%;
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//           position: absolute;
//           top: 0;
//         }

//         .price {
//           position: absolute;
//           left: 0;
//           bottom: 0;
//           padding: 5px 10px;
//           font-size: 1.125rem;
//           font-weight: 700;
//           color: $newGray;
//           background-color: rgba($white, 0.7);
//         }
//       }

//       .name {
//         font-size: 1.25rem;
//         font-weight: 400;
//         color: $newDark;
//         margin-top: 10px;
//         flex-grow: 1;
//         max-height: 48px;
//         overflow: hidden;
//       }

//       .cardBottom {
//         display: flex;
//         margin: 10px 0 1em;
//         justify-content: space-between;
//         flex-shrink: 0;

//         .category {
//           font-size: 18px;
//           color: #7a7a7a;
//         }

//         .commission {
//           font-size: 18px;
//           font-weight: 700;
//           color: #7a7a7a;
//         }
//       }
//     }
//   }

//   .productAddBtnSection {
//     position: fixed;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     background-color: #fff;
//     text-align: center;
//     box-shadow: 0 0 10px rgba($lightGray, 0.5);
//   }
// }
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #c6c6c6;
  margin: 0 0 0 auto;
}
.horizontalLine {
  position: absolute;
  height: 2px;
  background-color: #db3333;
  left: 0px;
  bottom: 5px;
  display: inline-block;
  transform: rotate(-13deg);
  transform-origin: 0 0;
  width: 47%;
}
.saleCircle {
  background-color: #e44a41;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 2%;
  left: 2%;
  width: 2.2rem;
  height: 2.2rem;
  text-align: center;
  font-size: 11px;
  display: grid;
  place-items: center;
}
.localCircle {
  background-color: #202721;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 2%;
  right: 2%;
  width: 3.5rem;
  height: 3.3rem;
  text-align: center;
  font-size: 11px;
  display: grid;
  place-items: center;
}

.selectedBox {
  background-color: #18e675;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.title {
  font-size: 45px !important;
  color: $newDark !important;
  margin: 1rem auto 3.75rem !important;
  font-weight: 700 !important;
  text-align: center;

  @media (max-width: 1278px) {
    font-size: 30px !important;
  }

  @media (max-width: 960px) {
    font-size: 24px !important;
  }

  @media (max-width: 600px) {
    font-size: 20px !important;
  }
}

.dropDownArrow {
  height: 10px;
  width: 10px;
  margin: 0 10px;
}

.link {
  font-size: 1rem;
  font-weight: 700;
  color: $newDark;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 10px;

  &.activeLink {
    color: $btnBg;
  }

  @media (max-width: 960px) {
    margin: 0 10px 0 0;
  }
}
