.cardBody{
    position: relative;
    border: 0;
    margin-bottom: 30px;
    margin-top: 2px;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    padding: 0px 0px 0px 12px;
    width: 100%;
    box-shadow: 0px 0px 10px 2px rgb(241,241,241);
    .featuredCard {
      background-color: #ffffff;
    }
  }