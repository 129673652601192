@import "../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

  .searchIcon {
    height: 100%;
    padding: 8px;
  }
}
.exportIconWrapper {

  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

.exportIcon{
  height: 100%;
    padding: 8px;
  
}
}
.export{
  padding-left: 200px !important;
}


/* React date picker */
.rdt {
  input.form-control {
    font-size: 15px !important;
  }
}

.clearIconWrapper {
  background: $lightGray1;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

  .clearIcon {
    height: 100%;
    padding: 8px;
  }
}

// .iconWrapper{
//   max-width: 145px  !important;
// }

.showAllCheckBox {
  max-width: 145px !important;
}

.logoutAllButton{
  font-size:18px !important;
}
