@import "./../../Assets/scss/custom-variables.scss";

.uploadIcon {
    border       : 1px solid $border;
    padding      : 7px 15px;
    font-size    : 20px;
    border-radius: 5px;

    span {
        right           : -7px;
        top             : -6px;
        width           : 16px;
        height          : 16px;
        line-height     : 16px;
        border-radius   : 4px;
        background-color: $btnBg;
        color           : $white;
        font-size       : 14px;
    }
}

.uploadedTag {
    padding      : 3px 10px 5px;
    margin-right : 10px;
    display      : inline-block;
    margin-bottom: 10px;
    border-radius: 5px;
    border       : 1px solid $border;

    i {
        font-size  : 11px;
        color      : $lightGray1;
        margin-left: 7px;
    }
}

.editPicIcon {
    width           : 48px;
    height          : 48px;
    border-radius   : 24px;
    background-color: $newGray;
    overflow        : hidden;
    text-align      : center;
    line-height     : 58px;
    left            : 120px;

    i {
        color    : $white;
        font-size: 28px;
    }

    input {
        width   : 100%;
        height  : 100%;
        top     : 0;
        left    : 0;
        opacity : 0;
        position: absolute;
    }
}

.profilePictureWrapper {
    height: 166px;
    width : 166px;
    margin: 0 auto;

    img {
        height         : 100%;
        width          : 100%;
        border-radius  : 50%;
        margin         : 0 auto;
        object-fit     : cover;
        object-position: top;
    }
}