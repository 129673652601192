.bmdLabelFloating {
  left: 0;
}

.errorInput label {
  color: #f44336 !important;
}

.errorInput input:focus {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}

.bmdIcon {
  position: absolute;
  top: 26px;
  right: 0;
  width: 20px;
  height: 20px;
}

.hasIcon {
  input {
    padding-right: 24px;
  }
}
