@import "./../../Assets/scss/custom-variables.scss";

.VideoMessageWrapper {
    width: 100%;

    .container {
        display    : flex;
        align-items: center;

        .videoContainer {
            box-shadow      : 0 0 10px rgba($lightGray1, 0.5);
            width           : 460px;
            height          : max-content;
            // margin          : 20px 0;
            background-color: #fff;
            border-radius   : 15px;
            min-width       : 70%;
            padding         : 15px;

            .video {
                width : 100%;
                height: 100%;
                display: block;
            }
        }

        .textSection {
            margin     : 0 0 0 10px;
            font-size  : 16px;
            color      : $newGray;
            font-weight: 400;
        }
    }
}
