.social-line{
    padding: $padding-general-x 0;
}

.social-line-big-icons{
    [class*="col-"]{
        border-right: 1px solid $gray-lighter;

        &:last-child{
            border: 0;
        }
    }

    .btn{
        margin: 0;
        width: 100% !important;
        padding-top: 45px;
        padding-bottom: 45px;

        .fa,
        .material-icons{
            font-size: 25px;
            line-height: 90px;
        }
    }
}

.subscribe-line{
    padding: $padding-general-x * 2 0;

    .card{
        margin-top: 30px;
    }
    &.subscribe-line-image{
        position: relative;

        background-position: top center;
        background-size: cover;

        .title{
            color: $white-color;
        }
        &:after{
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: block;
            left: 0;
            top: 0;
            content: "";
            background-color: rgba(0,0,0,.66);
        }

        .container{
            z-index: 2;
            position: relative;
        }
    }
}

.social-line-white,
.subscribe-line-white{
    background-color: $white-color;
}
.social-line-black,
.subscribe-line-black{
    @include radial-gradient(#232323,#585858);

    [class*="col-"]{
        border-color: rgba(255, 255, 255, 0.1);
    }
}
