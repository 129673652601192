@import "./../../Assets/scss/custom-variables.scss";

.tab {
  position: relative;
  display: flex;
  line-height: 25px;
  padding: 0px;
  gap: 10px;
  flex-wrap: wrap;

  & li {
    box-sizing: content-box;
    background: #c6c6c6;
    // width: 15%;
    // min-width: 10%;
    width: auto;
    text-align: center;
    // margin: 0 auto;
    cursor: pointer;
    color: white;
    text-align: center;
    padding: 7px 25px;
    cursor: pointer;
    font-size: 18px !important;

    &.active {
      background-color: #cc998d;
    }

    &:hover {
      background: #cc998d;
    }
  }
}
