@import "./../../Assets/scss/custom-variables.scss";

.pageHeading {
    font-size: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
}
.SubscriptionCheckbox{
    // vertical-align: text-bottom;
    padding-left: 30px;
    
}

.topPanel {
    padding-top: 5px;

    .topLinks {
        padding: 10px 0;

        li {
            display     : inline-block;
            padding-left: 15px;
            position    : relative;

            span {
                width           : 15px;
                height          : 15px;
                border-radius   : 8px;
                background-color: $pink;
                right           : 0;
                top             : 2px;
                position        : absolute;
            }

            img {
                max-width: 30px;
            }
        }
    }
}

.iconWrapper {
    background    : $btnBg;
    height        : 34px;
    width         : 34px;
    display       : inline-block;
    border-radius : 4px;
    margin-left   : 20px;
    text-align    : center;

    .icon {
        display: block;
        height : 100%;
        padding: 8px;
    }

    .duplicateIcon {
        padding: 8px 5px;
    }
}

.orderButton {
    font-size: 14px !important;
    padding: 7px 30px !important;
    margin: -20px 0  0 20px !important;
}


@include media-breakpoint-up(xl) {

    .pageHeading {
        font-size: 40px;
    }

    .topPanel {
        padding-top: 20px;
    }

}


@media (min-width: $desktopBreakM) {

    .pageHeading {
        font-size: 45px;
    }

    .topPanel {
        padding-top: 30px;
    }

}
