.OrderHistoryCard {
  border: 1px solid #e4e0e5;
  border-radius: 10px !important;
  min-height: 100%;

  .content {
    padding: 12px 22px 12px;
    padding-bottom: 12px !important;
  }

  .CustomerName {
    font-style: normal;
    font-weight: bold;
    font-size: 24.53px;
    color: #212721;
  }

  .OrderDate {
    font-style: normal;
    font-weight: bold;
    font-size: 20.53px;
    color: #c6c6c6;
  }

  .OrderId {
    font-style: normal;
    font-weight: normal;
    font-size: 20.53px;
    color: #7a7a7a;
  }

  .OrderPrice {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #212721;
  }
}

.gold {
  border-right: 10px solid #ffec86;
}

.red {
  border-right: 10px solid #f37c7c;
}

.default {
  border-right: 10px solid #cb998d;
}
