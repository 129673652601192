@import "../../Assets/scss/custom-variables.scss";

.iconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 20px;
  text-align: center;

  .icon {
    display: block;
    height: 100%;
    padding: 8px;
  }
}

.status {
  display: flex;
  flex-wrap: nowrap;
}

.Active {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}

.Inactive {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #dbdbdb;
  border-radius: 10%;
}
.cardBody {
  position: relative;
  border: 0;
  margin-bottom: 30px;
  margin-top: 2px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 0px 0 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);
  .featuredCard {
    background-color: #ffffff;
  }
}

.title {
  font-size: 24px;
  color: $newDark;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}

.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

  .searchIcon {
    height: 100%;
    padding: 8px;
  }
}

.emptyImage {
  padding: 5px;
  background: #f2f3f5;
  border: 1.18881px solid #f2f3f5;
  border-radius: 6px;
  width: fit-content;

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 34px;
    color: #212721;
    opacity: 0.2;
  }
}

.ProductImageWrapper {
  display: inline-block;
  position: relative;
  img {
    height: 50px;
    width: 50px;
    margin-top: 10px;
  }
}