/* PaymentComponent.module.css */

.embeddedComponentStyle {
   margin-left: 30px;
   margin-right: 30px;
   margin-bottom: 30px;
}

.buttonStyle {
    background-color: #ffa300;
    border: none;
    padding: 0px 0px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 100px;
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    margin-left: auto;
    display: block;
    margin-top: 40px;
}



.payabliPaymentForm {
    padding: 0px;
}

.cc-form {
    border: none;
}

input.component-input-error {
    border-color: #dc3545 !important;
}

.input {
    background-color: #dc3545 !important;
    height: 32px;
    margin-bottom: 1em;
    display: block;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
    outline: 0;
    width: 100%;
    font-size: 16px;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    position: relative;

    &.focused {
        border-bottom: 2px solid #9c27b0;
    }

    &.error {
        border-bottom: 2px solid #f44336 !important;
    }

    &.success {
        border-color: #69ba78 !important;
        color: #69ba78 !important;
    }
}

/* disabling floating labels */

.payabliPaymentForm label {
    left: 0;
    margin-left: 0;
    padding: 0 !important;
    top: -25px;
    font-size: 12px;
    display: block;
    margin-bottom: 10px;
    color: #727272;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 1;
    transform: none;
}

.cardNumberContainer input {
    padding: 10px 10px 10px 45px !important;
}

#main-loading-layer {
    background-color: transparent !important;
}

.first-upper.popover-body {
    font-size: 11px;
}

h4 {
    font-size: 20px;
}