@import "./../../Assets/scss/custom-variables.scss";

.tab {
  position: relative;
  display: flex;
  line-height: 25px;
  padding: 0px;

  & li {
    box-sizing: content-box;
    background: #c6c6c6;
    width: 15%;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    color: white;
    text-align: center;
    padding: 7px;
    cursor: pointer;
    font-size: 18px !important;

    &.active {
      background-color: #cc998d;
    }

    &:hover {
      background: #cc998d;
    }
  }
}

.cardBody {
  border: 0;
  margin-bottom: 30px;
  margin-top: 2px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 0px 0px 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);

  .featuredCard {
    background-color: #ffffff;
  }
}

.rollOver {
  display: flex;
  justify-content: flex-start;

  .selectItme {
    width: 80px;
    margin-left: 15%;
  }

  .selectItme2 {
    width: 80px;
    margin-left: 2%;
  }

  .changeButton {
    margin-left: 2%;

    button {
      padding: 5px 20px;
      border-radius: 5px;
      min-width: 90px;
    }
  }
}

.storeOpenClose {
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 18px;
      text-wrap: nowrap;
    }
    
  }

  .selectItme {
    width: 70px;
    // margin-left: 15%;
    @media screen and (max-width:1024px) {
      margin-left: 2%;
    }
  }

  .selectItme2 {
    width:70px;
    margin-left: 5%;
    @media screen and (max-width:1024px) {
      margin-left: 2%;
    }
  }

  .changeButton {
    margin-left: 5%;

    button {
      padding: 5px 20px;
      border-radius: 5px;
      min-width: 90px;
      @media screen and (max-width:1024px) {
        min-width:50px;
        font-size: 18px;
      }
    }
  }
}



.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 12px;

  .searchIcon {
    height: 100%;
    padding: 8px;
    margin-bottom: 5px;
  }
}

.floderIcon {
  margin-bottom: 12px;
}

.addZip {
  box-sizing: content-box;
  background: #f2f3f5;
  color: #000000;
  padding: 40px;
  border-radius: 8px;
  width: 15%;

  &.active {
    background-color: #cc998d;
  }

  &:hover {
    background: #cc998d;
  }
}

.searchIcon {
  cursor: pointer;
}

.spacer {
  width: 34px;
  height: 34px;
  display: block;
}

.bannerFormRadio {
  display: flex;
  margin-top: 25px;

  label {
    span:first-child {
      display: none;
    }

    span {
      font-size: 1.2em;
      margin-left: 2px;
      padding: 0;
    }
  }
}

.status {
  display: flex;
  flex-wrap: nowrap;
}

.Active {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}

.Inactive {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #dbdbdb;
  border-radius: 10%;
}

.isCloseCheckbox {
  // margin-left: 15%;
  @media screen and (max-width:1024px) {
    display: flex;
    justify-content: end;
  }

  label {
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    span {
      padding: 5px !important;
    }
  }
}
.burq_status_toggle_button{
  label{
    margin-left: -8px !important;
  }
}