.DayPicker {
  border: 1px solid #d2d2d2;
  width: 100%;
}
.DayPicker-NavButton--prev {
  left: 1.5rem !important;
  right: auto !important;
}
.DayPicker-NavButton--next,
.DayPicker-NavButton--prev {
  background-image: url("./../images/arrow.svg") !important;
  transform: rotate(-90deg) scale(1.5);
}
.DayPicker-NavButton--next:focus,
.DayPicker-NavButton--prev:focus {
  outline: none;
}
.DayPicker-NavButton--prev {
  transform: rotate(90deg) scale(1.5);
}
.DayPicker-Caption {
  text-align: center !important;
  /* // background-color: #e4e0e5; */
  background-color: rgba(#c08374, 0.7) !important;
  padding: 1em 0.5em !important;
  margin-bottom: 0 !important;
}
.DayPicker-Weekdays {
  background-color: rgba(#c08374, 0.7) !important;
  color: #ffffff;
  /* // background-color: #e4e0e5; */
  text-transform: uppercase;
}
.DayPicker-Weekday {
  color: #000 !important;
}
.DayPicker-Weekdays abbr {
  text-decoration: none !important;
}
.DayPicker-Day {
  /* // width: 3.125rem; */
  cursor: pointer;
}
.DayPicker-Day:hover {
  border-radius: 0 !important;
  background: rgba(#c08374, 0.1) !important;
  color: #000 !important;
}
.DayPicker-Month {
  margin: 0 !important;
  width: 100%;
}
.DayPicker-Day--today {
  border-radius: 0 !important;
  background: transparent !important;
  color: #000000 !important;
}
/* .DayPicker-Day--disabled{
  color: #999999;
} */
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 0 !important;
  background: #c08374 !important;
  color: white !important;
  /* // width: 3.125rem !important; */
}
