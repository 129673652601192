.nbs-flexisel-container {
    position:relative;
    max-width:100%;
}
.nbs-flexisel-ul {
    position:relative;
    width:99999px;
    margin:0px;
    padding:0px;
    list-style-type:none;
    text-align:center;
}

.nbs-flexisel-inner {
    overflow: hidden;
    width:100%;
}

.nbs-flexisel-item {
    float:left;
    margin:0px;
    padding:0px;
    cursor:pointer;
    position:relative;
    line-height:0px;
}
.nbs-flexisel-item img {
    max-width: 100%;
    cursor: pointer;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

/*** Navigation ***/

.nbs-flexisel-nav-left,
.nbs-flexisel-nav-right {
    top:40%;
}

.nbs-flexisel-nav-left {
    left: -20px;
}

// .nbs-flexisel-nav-left:before {
//     content: "<"
// }

.nbs-flexisel-nav-left.disabled {
    opacity: 0.4;
}

.nbs-flexisel-nav-right {
    right: -20px;
}

// .nbs-flexisel-nav-right:before {
//     content: ">"
// }

.nbs-flexisel-nav-right.disabled {
    opacity: 0.4;
}

.nbs-flexisel-nav-right,
.nbs-flexisel-nav-left{
    position: absolute;
    cursor: pointer;
    z-index: 100;
    opacity: 0.5;
}
