@import "../../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
    background: $btnBg;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .searchIcon {
      height: 100%;
      padding: 8px;
    }
  }
  
  .export{
    padding-left: 150px !important;
    }
  
  .clearIconWrapper {
    background: $lightGray1;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .clearIcon {
      height: 100%;
      padding: 8px;
    }
  }
  
  .PieChartList {
    ul {
      margin-right: 20px;
      li {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        white-space: nowrap;
        padding: 4px;
        text-align: left;
        list-style: disc;
        span {
          font-weight: 700;
        }
      }
    }
    // background-color: red;
  }