@import "./../../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

  .searchIcon {
    height: 100%;
    padding: 8px;
  }
}

.title {
  font-size: 30px;
  color: $newGray;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}


.iconWrapper {
  background: $btnBg;
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 0px;
  text-align: center;

  .icon {
    display: block;
    height: 100%;
    padding: 8px;
    margin: 12px;
  }
}

.cardBody {
  position: relative;
  border: 0;
  margin-bottom: 30px;
  margin-top: 2px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 0px 0 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);
  .featuredCard {
    background-color: #ffffff;
  }
}

.clearIconWrapper {
  background: $lightGray1;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

.clearIcon {
    height: 100%;
    padding: 8px;
  }
}