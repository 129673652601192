@import "../../../Assets/scss/custom-variables.scss";

.iconWrapper {
  background: $btnBg;
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 0px;
  text-align: center;

  .icon {
    display: block;
    height: 100%;
    padding: 8px;
  }
}
.ProducatImageWapper {
  display: inline-block;
  position: relative;
  img {
    height: 50px;
    width: 50px;
    margin-top: 10px;
  }
}
.cardBody {
  position: relative;
  border: 0;
  margin-bottom: 30px;
  margin-top: 2px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 0px 0px 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);
  .featuredCard {
    background-color: #ffffff;
  }
}

.GridTable {
  display: grid;
  grid-template-columns: 6% 34% 13% 14% 14% 17%;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #f2f3f5;
  & > div {
    padding: 1rem 0;
    margin-bottom: 0.5rem;
  }
  .header {
    // background-color: red;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
    line-height: 0px;
    margin-right: 5px;
    font-weight: bold;
    color: #c2c2c2;
  }
}

.NestedGroup {
  li {
    padding: 0px 0px 0px 0px;
    text-indent: 0;
    ol {
      padding: 0px 0px 0px 0px;
      background-color: #f9f8f7;
      margin-bottom: 5px;
    }
  }
  li::before {
    display: none;
    background-color: red;
  }
}

.emptyImage {
  padding: 12px;
  background: #f2f3f5;
  border: 1.18881px solid #f2f3f5;
  border-radius: 6px;
  width: fit-content;

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 44px;
    color: #212721;
    opacity: 0.2;
  }
}

.status {
  display: flex;
  flex-wrap: nowrap;
}

.Active {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}

.Inactive {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #dbdbdb;
  border-radius: 10%;
}