.InternalNotesAccordianStyle {
    .MuiAccordion {
        border-top      : 1.5px solid #f2f3f5 !important;
        // border-bottom: 1.5px solid #F2F3F5 !important;
        box-shadow      : none;
        border-radius   : 0;
    }

    .summary {
        // margin    : 10px 0;
        padding: 10px 15px;
        min-height: 25px;
        border-right: 0 !important;
        border-left: 0 !important;      
    }

    .iconArrow {
        margin-right    : 1.25rem;
        background-color: #cb998d;
        width           : 1.5rem;
        height          : 1.5rem;
        // padding: 10px;
        border-radius   : 5px;
        transition      : transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .iconRotation {
        svg {
            transform: rotate(180deg);
        }
    }

    .author {
        font-weight: normal;
        font-size  : 20px;
        color      : #212721;
    }

    .desc {
        font-weight: normal;
        font-size  : 18px;
        color      : #7a7a7a;
    }

    .time {
        font-weight: normal;
        font-size  : 14px;
        text-align : right;
        color      : #7a7a7a;
        margin-left: auto;
    }
}