@import './../../../Assets/scss/custom-variables.scss';

.modalDialog {
  max-width: 800px !important;
  width: 90%;

  .modalContent {
    @include shadow-z-5();
    border-radius: $border-radius-large;
    border: none;

    .card-signup {
      margin: 0;

      .modal-header {
        padding-top: 0;
      }
    }
  }

  .modalTitle {
    color: $newDark;
    font-weight: $bold;
    line-height: 1;
    font-size: 30px;
  }

  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px;
  }

  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modalBody {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
  }

  // Footer (for actions)
  .modal-footer {
    border-top: none;
    padding: 24px;

    &.text-center {
      text-align: center;
    }

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;

      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }

  .Settings_tab__2hHed li {
    background-color: #fff;
  }

  .modalBody + .modal-footer {
    padding-top: 0;
  }
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.active {
  color: #cb998d !important;
}
.tab {
  position: relative;
  display: flex;
  line-height: 25px;
  padding: 0px;
  width: 100%;
}

.tab li {
  box-sizing: content-box;
  color: #c6c6c6;
  width: 15%;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
  padding: 4px;
  cursor: pointer;
  font-size: 18px !important;
}
.modal {
  .modal-dialog {
    margin-top: 130px;
  }

  .modal-header .close {
    color: $gray-light;

    &:hover,
    &:focus {
      opacity: 1;
    }

    i {
      font-size: 16px;
    }
  }
}

@media (max-width: $deviceBreak) {
  .modalDialog {
    margin: 0 5%;
  }
}
.status {
  display: flex;
  flex-wrap: nowrap;
  margin-top: -11px;
  .Active {
    background-color: #ddf0dd;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }
  .Inactive {
    background-color: #dbdbdb;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }
}
.timerFormRadio {
  display: flex;
  margin-left: 0px;
  label {
    span:first-child {
      display: none;
    }
    span {
      font-size: 1.3em;
      margin-left: 10px;
      padding: 0;
    }
  }
}
.repeatText {
  font-size: 0.8em;
  color: #7a7a7a;
  text-align: left;
}
.MuiOutlinedInput-root {
  width: 100% !important;
}
.Days {
  display: inline;
  cursor: pointer;
  span {
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 0.8rem;
    color: #f2f3f5 !important;
    background-color: #cb998c;
    border-radius: 50px;
    margin-right: 20px;
    text-align: center;
    cursor: pointer;
  }
}
.SelectedDay {
  display: inline;
  cursor: pointer;
  span {
    display: inline-block;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 0.8rem;
    color: black !important;
    background-color: #d5d8d5;
    border-radius: 50px;
    margin-right: 20px;
    text-align: center;
  }
}

.error{
  color: #f44336;
  width:100%;
  font-size: 100%;
  margin-top: -15px;
}