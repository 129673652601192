@import "material-kit/bootstrap/scss/functions";
@import "material-kit/bootstrap/scss/variables";
@import "material-kit/bootstrap/scss/mixins";

@import "colors";
@import "shadows";

$fontFamily: 'Kern', sans-serif;
$bold        : 700;
$subTitleSize: 30px;

$black       : #000;
$white       : #fff;
$primaryDark : #2b2b2b;
$pink        : #f3cfda;
$mediumGray  : #8a8989;
$lightGray   : #c2c2c2;
$lightGray1  : #c6c6c6;
$green       : #9ecac9;
$btnBg       : #CB998D;
$light4      : #f9f9f9;
$light4Border: #b8dad9;
$darkGreen   : #8FC897;
$outTime     : #f2b4c7;
$outTimeRed  : #F37C7C;
$tableBorder : #f2f3f5;
$darkGray    : #414141;
$newDark     : #212721;
$darkGray1   : #171717;
$red         : #F30000;
$border      : #dedede;
$newGray     : #7a7a7a;
$purple      : #9c27b0;
$inputBorder : #E4E0E5;

$newClockColor: #8FC897;

$deviceBreak  : 767px;
$tabletBreak  : 991px;
$desktopBreak : 1399px;
$desktopBreakM : 1400px;
/*$brand-primary: $purple !default;*/
$brand-primary: $btnBg;

$gray-lighter: rgba($black, 0.12) !default;
$gray-light  : #999 !default;
$gray-alpha  : .54 !default;
$gray        : #555 !default; // spec color
$gray-dark   : rgba($black, 0.87) !default; // used for text color - others use grey-600 which is considerably lighter

$border-radius-base   : 3px !default;
$border-radius-small  : 2px !default;
$border-radius-large  : 6px !default;
$border-radius-huge   : 10px !default;
$border-radius-label  : 12px !default;
$border-radius-extreme: 30px !default;

$boxShadow: "0 4px 20px 0 rgba("+hexToRgb($black)+",.14), 0 7px 10px -5px rgba("+hexToRgb($pink)+",.4)";
