.errorWrapper {
    border       : 2px solid #F37C7C;
    border-radius: 10px;
    padding      : 20px 30px;
}

.errorItem {
    color      : #7a7a7a;
    font-size  : 18px;
    line-height: 36px;
}

.ml15 {
    margin-left: 15px;
}

.ml40 {
    margin-left: 40px;
}