@import './../../../Assets/scss/custom-variables.scss';

.remindersTab {
  ul {
    display: flex;
    flex-direction: row;
    li {
      margin-left: 0;
      margin-right: 2px;
      padding: auto;
      // padding: 2px 10px 2px 10px;
    }
    li:nth-child(1) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    li:nth-child(2) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    // background-color: green;
  }
}

.completed {
  color: #abd6b1;
  text-decoration: line-through;
}
.pending {
  color: red;
}
.tabButtonCustom {
  box-sizing: content-box;
  background: #c6c6c6;
  width: 15%;
  text-align: center;
  margin: 0 auto;
  color: white !important;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  font-size: 20px !important;
  border: none !important;
  margin-right: 2px !important;
  font-weight: bold;
}
.tabButtonCustomActive {
  box-sizing: content-box;
  background: #cc998d;
  width: 15%;
  text-align: center;
  margin: 0 auto;
  color: white !important;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  font-size: 20px !important;
  border: none !important;
  margin-right: 2px !important;
  font-weight: bold;
}
.tabButtonCustom:hover {
  box-sizing: content-box;
  background: #cc998d;
  width: 15%;
  text-align: center;
  margin: 0 auto;
  color: white !important;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  font-size: 20px !important;
  border: none !important;
  margin-right: 2px !important;
  font-weight: bold;
}
.status {
  display: flex;
  flex-wrap: nowrap;
}
.Active {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}
.Inactive {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #dbdbdb;
  border-radius: 10%;
}
.MuiTabs-flexContainer{
  display: block !important;
}