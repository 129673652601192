@import "../../Assets/scss/custom-variables.scss";

.iconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 20px;
  text-align: center;

  .icon {
    display: block;
    height: 100%;
    padding: 8px;
  }
}
.OrderSummeryCard {
  background: #f2f3f5;
  border-radius: 10px;
  padding: 30px 15px 23px;
  margin: 0;
  .OrderSummeryItem {
    .OrderSummeryDetail {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 44px;
      color: #212721;
    }
    .OrderSummeryLabel {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #7a7a7a;
    }
  }
  .OrderSummerySignatureItem {
    display: flex;
    align-items: center;
    .OrderSummeryLabel {
      margin-left: 25px;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
.rt-filter-wapper {
  min-height: 0px;
}
.ProducatImageWapper {
  display: inline-block;
  position: relative;
  img {
    height: 36px;
    width: 36px;
    margin-top: 5px;
  }
  .ProducatBadge {
    position: absolute;
    top: 0;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-family: "Lato";
    font-weight: 900;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }
  .ProducatBadgeRed {
    background: #f37c7c;
  }
  .ProducatBadgeGreen {
    background: #8fc897;
  }
  .ProducatBadgeGrey {
    background: #c6c6c6;
  }
}

.subTotalBlock,
.totalBlock {
  .totalBlockItem,
  .mainTotalBlockItem {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 400;
    line-height: 45px;
    color: #c4c4c4;
  }
  .totalBlockBtn {
    border: 1px solid #cb998d;
    padding: 5px 5px;
    font-size: 10px;
  }
  .borderBottom {
    border-bottom: 1px solid #e8e8e8;
  }
}
.subTotalBlock {
  border-bottom: 1px solid #e8e8e8;
}
.totalBlock {
  .mainTotalBlockItem {
    font-size: 24px;
    font-weight: 700;
    line-height: 45px;
  }
  .totalBlockItem {
    font-size: 18px;
    line-height: 24px;
  }
}

.mapWrap {
  border: 1px solid rgba($lightGray, .4);
  padding: 8px;

  iframe {
    display: block;
  }
}

.deliveryMatrixFormRadioLabel span {
    font-size: 24px;
    font-weight: bold !important;
    color: $newDark !important;
}

.modalImageWrapper {
  height: 85px;
}
