.bmdLabelFloating {
  left: 0;
}

.errorInput label {
  color: #f44336 !important;
}

.errorInput input:focus {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}

.bmdIcon {
  position: absolute;
  top: 26px;
  right: 0;
  width: 20px;
  height: 20px;
}

.hasIcon {
  input {
    padding-right: 24px;
  }
}
.dt {
  .caret {
    visibility: hidden;
    width: 120px;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 140%;
    left: 50%;
    margin-left: -60px;
    &::after {
      content: '';
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
  &:hover {
    .caret {
      visibility: visible;
    }
  }
}
