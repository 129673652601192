.Menu {
  li {
    cursor: pointer;
    font-weight: 600;
    &:hover {
      color: var(--blush);
    }
  }
  nav {
    & > ul > li {
      padding: 1rem 0;
    }
  }
}

.SortAndSearch {
  padding: 15px 30px;
  background-color: #f2f3f5;
}

.List {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 567px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 2rem 2rem;
  padding-bottom: 9rem;
}

.Card {
  position: relative;
  .Image {
    display: block;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.7s ease;
    &:hover {
      transform: scale(1.03);
    }
    margin: 0;
    div {
      display: block;
      box-sizing: border-box;
      padding-top: 126.24584717607974%;
      z-index: -1;
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
  .priceRange {
    position: absolute;
    z-index: 3;
    width: max-content;
    padding: 0 0.4rem;
    bottom: 0;
    left: 0;
    background-color: rgba(#f2f3f5, 0.5);
    margin: 0;
  }
  //   height: 300px;
  .SoldOut {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    border: 1px solid #000;
    border-left: 0;
    border-right: 0;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
}

.Variation {
  cursor: pointer;
  background-color: #fff;
  width: 14.4375rem;
  padding: 0.6rem;
  .Image {
    position: relative;
    img {
      width: 100%;
      height: 16rem;
    }
  }
  .SoldOut {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    border: 1px solid #000;
    border-left: 0;
    border-right: 0;
    transform: translate(-50%, -50%);
    background-color: #fff;
  }
}

.DropdownMenu {
  min-height: 300px;
  padding: 1rem 2rem;
  position: absolute;
  background-color: #fff;
  z-index: 10;
  top: 56px;
  width: 100%;
  box-shadow: 0px 10px 22px -1px rgba(0, 0, 0, 0.43);
  //   &::before {
  //     position: absolute;
  //     height: 100%;
  //     width: 100%;
  //     content: "";
  //     z-index: -1;
  //   }
  p {
    font-weight: 600;
    font-size: 16px;
  }
  ul {
    padding-left: 0;
    li {
      color: #7a7a7a;
      font-weight: 300;
    }
  }
}

.LocalDelivery {
  width: 5rem;
  height: 5rem;
  background-color: #202721;
  text-align: center !important;
  color: #fff;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 50%;
  display: grid !important;
  place-items: center;
  position: absolute;
  right: 1% !important;
  padding: 0 !important;
  bottom: 1% !important;
  z-index: 3 !important;
  padding: 0 0.4rem;
  bottom: 0;
  margin: 0;
}
