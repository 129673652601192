@import '../../../Assets/scss/custom-variables.scss';

.iconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 20px;
  text-align: center;
  .icon {
    display: block;
    height: 100%;
    padding: 8px;
  }
}

.ProducatImageWapper {
  display: inline-block;
  position: relative;
  img {
    height: 60px;
    width: 50px;
    margin-top: 10px;
  }
}

.status {
  display: flex;
  flex-wrap: nowrap;
}
.Active {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}
.Inactive {
  display: block;
  padding: 10px;
  text-align: justify;
  background-color: #dbdbdb;
  border-radius: 10%;
}
.cardBody {
  position: relative;
  border: 0;
  margin-bottom: 30px;
  margin-top: 2px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 0px 0 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);
  .featuredCard {
    background-color: #ffffff;
  }
}

.addCategory {
  display: inline-block;
  box-sizing: border-box;
  background-color: #f9f8f7;
  margin-left: 10px;
  margin-bottom: 10px;
  span {
    margin-left: 10px;
  }
  .closeIconWrapper {
    margin-left: 16px;
    margin-right: 10px;
  }
  .closeIcon {
    margin-bottom: 6px;
  }
}

.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

  .searchIcon {
    height: 100%;
    padding: 8px;
  }
}

.GridTable {
  display: grid;
  grid-template-columns: 10% 10% 80%;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #f2f3f5;
  & > div {
    padding: 0.5rem 0;
  }
  &:hover {
    background: rgba($btnBg, 0.15);
  }
  .header {
    // background-color: red;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
    line-height: 0px;
    margin-right: 5px;
    font-weight: bold;
    color: #c2c2c2;
  }
}
.GridTableHeader {
  display: grid;
  grid-template-columns: 10% 10% 80%;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #f2f3f5;
  & > div {
    padding: 1rem 0;
    margin-bottom: 0.5rem;
  }
  .header {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
    line-height: 0px;
    margin-right: 5px;
    font-weight: bold;
    color: #c2c2c2;
    text-align: left;
  }
}
.NestedGroup {
  li {
    padding: 0px 0px 0px 0px;
    text-indent: 0;
    ol {
      background-color: #f9f8f7;
    }
  }
  li::before {
    display: none;
    background-color: red;
  }
}
