.inline-link-white {
  text-decoration: none;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  transition: all 0.1s ease;
  &:hover {
    color: #ca998d;
    text-decoration: none;
    border-bottom: 1px solid #ca998d;
  }
}
