@import "../../../Assets/scss/custom-variables.scss";    

.dataLeft{
padding-left: 100px;;
}

.input{
    width: 16% !important;
}

.searchIconWrapper {
    background: $btnBg;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .searchIcon {
      height: 100%;
      padding: 8px;
    }
  }

.searchIconWrapper {
    background: $btnBg;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .searchIcon {
      height: 100%;
      padding: 8px;
    }
  }
  
  .export{
    padding-left: 150px !important;
    }
  
  .clearIconWrapper {
    background: $lightGray1;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .clearIcon {
      height: 100%;
      padding: 8px;
    }
  }