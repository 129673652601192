@import "./../../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

  .searchIcon {
    height: 100%;
    padding: 8px;
  }
}

.title {
  font-size: 30px;
  color: $newGray;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}

.status {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 !important;
}
.Active {
  display: block;
  padding: 10px !important;
  text-align: justify;
  background-color: #ddf0dd;
  margin-right: 20px;
  border-radius: 10%;
}
.Inactive {
  display: block;
  padding: 10px !important;
  text-align: justify;
  background-color: #dbdbdb;
  border-radius: 10%;
}
