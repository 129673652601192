.bmdLabelFloating {
  left: 0;
}

.errorInput label {
  color: #f44336 !important;
}

.errorInput input:focus {
  background-image: linear-gradient(
      to top,
      #f44336 2px,
      rgba(156, 39, 176, 0) 2px
    ),
    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}

.FormControlLabel {
  margin-bottom: 17px;
  padding-top: 27px;
}

.MuiFormControlLabel-label {
  font-size: 24px;
  font-weight: 700;
  color: #212721;
  padding-left: 10px;
}
