@import "./../../../Assets/scss/custom-variables.scss";

.modalDialog {
  max-width: 600px !important;
  width: 90%;

  .modalContent {
    @include shadow-z-5();
    border-radius: $border-radius-large;
    border: none;

    .card-signup {
      margin: 0;

      .modal-header {
        padding-top: 0;
      }
    }
  }

  .modalTitle {
    color: $newDark;
    font-weight: $bold;
    line-height: 0.5;
    font-size: 30px;
  }

  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modalBody {
    padding: 24px 24px 16px 24px;
  }

  .modalMessage {
    font-size: 20px;
    margin-top: 40px;
  }

  .modalBody + .modal-footer {
    padding-top: 0;
  }
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  .modal-dialog {
    margin-top: 130px;
  }

  .modal-header .close {
    color: $gray-light;

    &:hover,
    &:focus {
      opacity: 1;
    }

    i {
      font-size: 16px;
    }
  }
}

@media (max-width: $deviceBreak) {
  .modalDialog {
    margin: 0 5%;
  }
}
