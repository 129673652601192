@import "./../../Assets/scss/custom-variables.scss";

.leftPanel {
    max-width : 260px;
    background: $white;
    flex-grow: 1;
    flex-shrink: 0;
    z-index   : 1;

    .logo {
        padding: 16px 10px;

        img {
            max-width: 200px;
        }
    }

    .leftNavigation {
        padding: 20px 0;
        max-height  : calc(100vh - 100px);
        /*overflow: auto;*/

        .navItem {
            padding-bottom: 12px;

            .navLink {
                text-transform: none;
                font-size     : 20px;
                color         : $newDark;
                font-weight   : $bold;
                transition: color .3s;

                &:hover {
                    color: $mediumGray;
                }
            }

            ul {
                margin-top : 5px;
                margin-left: 15px;

                .navLink {
                    font-size  : 18px;
                    font-weight: normal;
                    color: $newGray;
                    transition: color .3s;

                    &:hover {
                        color: $brand-primary;
                    }

                    &.active {
                        font-weight: bold;
                        color: $brand-primary;
                    }
                }
            }
        }
    }
}

/*@media (max-width: $desktopBreak) {
    .leftPanel {
        max-width: 300px;
    }
}

@media (max-width: $tabletBreak) {
    .leftPanel {
        max-width       : 270px;
        margin-left     : -280px;
        height          : 100%;
        transition      : 0.4s ease-in-out;
        z-index         : 1;
        box-shadow      : 1px 0 10px $lightGray;

        .logo {
            padding: 10px;

            img {
                max-width: 95%;
            }
        }
    }
}


@media (max-width: $deviceBreak) {
    .leftNavigation {
        height : calc(100vh - 86px);
        padding: 20px 0;
    }
}*/


@include media-breakpoint-up(xl) {

    .leftPanel {
        max-width: 300px;

        .logo {
            padding: 22px 15px;

            img {
                max-width: 249px;
            }
        }

        .leftNavigation {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

}


@media (min-width: $desktopBreakM) {

    .leftPanel {
        max-width: 325px;

        .logo {
            padding: 28px 20px;
        }

        .leftNavigation {
            padding-left: 20px;
            padding-right: 20px;

            .navItem {
                .navLink {
                    font-size: 22px;
                }

                ul {
                    .navLink {
                        font-size: 20px;
                    }
                }
            }
        }
    }

}
