@import "./../../Assets/scss/custom-variables.scss";
.seo {
  display: flex;
  .grid {
    display: flex;
    margin-left: auto;
    align-items: center;
    label {
      margin: 0 16px 0 0;
      font-size: 1rem;
    }
  }
}
.grid {
  display: flex;
  margin-left: auto;
}
.searchIconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;

  .searchIcon {
    height: 100%;
    padding: 8px;
  }
}
.seotitle {
  font-size: 24px;
  color: $newDark;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}
.title {
  font-size: 24px;
  color: $newDark;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  // margin-left: 50px;
}
.featuredTitle {
  font-size: 30px;
  color: black;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  display: flex;
  vertical-align: middle;
  margin-top: 20px;
}
.circle {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  padding: 0px;
  background: #e5e5e5;
  color: #c9c9c9;
  font-size: 35px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  margin: 15px 20px 0px -20px;
}
.banners {
  display: flex;
  justify-content: flex-start;
  margin: 0px 20px 0px 10px;
}
.inputItem {
  display: flex;
}
.featured {
  display: flex;
  margin: 0 0 0 20px;
}
.featured {
  display: flex;
  flex-direction: row;
  a {
    margin-top: 10px;
  }
}
.cardBody {
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 6px 0 12px;
  width: 100%;
  .featuredCard {
    background-color: #f9f9f9;
  }
}

.tableStyle {
  td {
    padding: 20px 10px !important;
  }
}

.tableimg {
  width: 62px;
  height: 67px;
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// ol {
//   counter-reset: item;
//   margin: 0 0 1.5em;
//   padding: 0;
//   > li {
//     counter-increment: item;
//     list-style-type: none;
//     margin: 0;
//     padding: 0 0 0 2rem;
//     text-indent: -1.2rem;
//     &::before {
//       content: counter(item) ".";
//       display: inline-block;
//       font-weight: bold;
//       padding-right: 0.5rem;
//       text-align: right;
//       width: 1.5rem;
//     }
//   }
// }

.gotoList {
  list-style: none;
  li {
    color: #7a7a7a;
    font-size: 16px;
    line-height: 44px;
    font-weight: normal;
    position: relative;
    padding-left: 23px;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #c4c4c4;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      left: 0;
    }
  }
}
.closeIconWrapper {
  background-color: #e5e5e5;
  margin-left: 88%;
  border-radius: 100%;
  color: black;
  padding: 2px;
}
.closeIcon {
  height: 20px;
  padding: 4px;
  text-align: center;
  margin-bottom: 4px;
}

.uploadBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e0e5;
  background: #e4e0e5;
  height: 100px;
  width: 140px;
  margin-top: 12px;
}
.toggalIconWrapper {
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  // margin-right: 10px;
  .toggalIconDown {
    height: 100%;
    padding: 11px;
    margin: -2px 0 0px -4px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .toggalIconUp {
    height: 100%;
    padding: 11px;
    transform: rotate(-180deg);
    margin: -2px 0 0px -4px;
  }
}
.featuredItem {
  text-align: center;
  .toggalIconDown {
    height: 10%;
    transform: rotate(0deg);
  }
  .toggalIconUp {
    height: 10%;
    transform: rotate(-180deg);
  }
}
.rowElement {
  padding: 0px 14px 0 20px;
}
.gotoBody {
  position: relative;
  border: 0;
  margin-bottom: 30px;
  margin-top: 2px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 6px 0 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);
  .featuredCard {
    background-color: #ffffff;
  }
}
.imageWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.imageWrapper:hover .image {
  opacity: 0.1;
}
.imageWrapper:hover .removeImage {
  display: block;
}
.removeImage {
  display: none;
  position: absolute;
  top: 35%;
  left: 45%;
  width: 30px;
  height: 30px;
  color: black;
}
.bannerFormRadio {
  display: flex;
  margin-top: 25px;
  label {
    span:first-child {
      display: none;
    }
    span {
      font-size: 1.2em;
      margin-left: 2px;
      padding: 0;
    }
  }
}
.result {
  // margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e0e5;
  background: #e4e0e5;
  height: 95px;
  width: 125px;
}
.bottomBannerFormRadio {
  display: flex;
  margin-top: 20px;
  margin-left: 14px;
  label {
    span:first-child {
      display: none;
    }
    span {
      font-size: 1.3em;
      margin-left: 0px;
      padding: 0;
    }
  }
}
.editSeo {
  font-size: 12px;
  text-decoration: none;
  font-weight: bold;
  background-color: $btnBg;
  color: #ffffff;
  height: 22px;
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  text-align: center;
}

.paragraphBackground {
  color: red;
  margin-left: 16px;
}