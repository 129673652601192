@import "./../../../Assets/scss/custom-variables.scss";

.iconWrapper {
  background: $btnBg;
  height: 34px;
  width: 34px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  margin-left: 20px;
  text-align: center;

  .icon {
    display: block;
    height: 100%;
    padding: 8px;
  }
}

.heading {
  color: $lightGray1;
  font-size: 14px;
}
.content {
  font-size: 20px;
  padding-top: 5px;
}

.viewRequestColor {
  background-color: #fbfbfb;
}