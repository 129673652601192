/* Form control custom styles */
.MuiInput-underline {
  &::before {
    border-bottom-color: $inputBorder !important;
  }

  &::after {
    border-bottom-color: $btnBg !important;
  }

  &:hover:not(.Mui-disabled)::before {
    border-bottom-color: $inputBorder !important;
  }
}

.form-control {
  font-size: 16px;
  filter: none !important; // Remove autofill firefox
  color: $newDark;
}

.form-control,
.is-focused .form-control {
  background-image: linear-gradient(to top, $brand-primary 2px, rgba($brand-primary, 0) 2px),
                    linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}

.MuiInputBase-root {
  color: $newDark !important;
}

.MuiNativeSelect-select {
  &.MuiInputBase-input {
    height: 31px;
  }
  &:focus {
    background-color: transparent !important;
  }
}

.auto-select {
  padding-top: 0;
  padding-bottom: 0;

  .MuiInput-underline:before {
    border-bottom-color: #d2d2d2 !important;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-width: 1px;
  }

  .MuiFormControl-root {
    margin-top: 0;
    margin-bottom: 16px;
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }

  .MuiInput-formControl {
    padding-top: 16px;
  }

  .MuiAutocomplete-input {
    font-size: 20px;
    color: $newDark;

    &.MuiInputBase-input {
      height: 31px;
    }
  }

  .MuiFormLabel-root {
    font-size: 1.25rem !important;
    color: #aaa;
    //top: 1.5rem !important;
  }
}

// Remove autofill chrome
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 60px 0 0 white inset !important;
  border-bottom: 1px solid #d2d2d2;

  .is-focused & {
    border-bottom: 2px solid $brand-primary;
  }
}

fieldset[disabled][disabled] .form-control,
.form-control.disabled,
.form-control:disabled,
.form-control[disabled] {
  background-image: none;
}

/* React date picker */
.rdt {
  input.form-control {
    font-size: 18px !important;
    height: 44px;
    color: $newDark;

    &:focus {
      background-image: linear-gradient($btnBg, $btnBg),
      linear-gradient(#d2d2d2, #d2d2d2) !important;
    }
  }
}

.rdtPicker {
  top: 48px;
}

.btn {
  box-shadow: none;
}
