@import "./../../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
    background    : $btnBg;
    height        : 34px;
    width         : 34px;
    display       : inline-block;
    vertical-align: middle;
    border-radius : 4px;
    text-align    : center;

    .searchIcon {
        height : 100%;
        padding: 8px;
    }
}

.clearIconWrapper {
    background    : $lightGray1;
    height        : 34px;
    width         : 34px;
    display       : inline-block;
    vertical-align: middle;
    border-radius : 4px;
    text-align    : center;

    .clearIcon {
        height : 100%;
        padding: 8px;
    }
}


.searchIcon {
    cursor: pointer;
}

.spacer {
    width: 34px;
    height: 34px;
    display: block;
}

.imageContainer {
    position: relative;
    width: 50px;
    .imageSubContainer {
        height: 54px;
        width: 50px;
        overflow: hidden;
        .image{
            width: 100%;
            height: auto;
        }
        .imageNotification {
            position: absolute;
            background: #F37C7C;
            color: #fff;
            top: -9px;
            right: -10px;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8rem;
        }
    }
}

.status {
    display: flex;
    flex-wrap: nowrap;
  }
  
  .Active {
    display: block;
    padding: 10px;
    text-align: justify;
    background-color: #ddf0dd;
    margin-right: 20px;
    border-radius: 10%;
  }
  
  .Inactive {
    display: block;
    padding: 10px;
    text-align: justify;
    background-color: #dbdbdb;
    border-radius: 10%;
  }