@import "./../../../Assets/scss/custom-variables.scss";

.modalDialog {
  max-width: 50% !important;
  width: 100%;
  height: 300px;
  position: relative;

  .modalContent {
    // @include shadow-z-5();
    border-radius: $border-radius-large;
    border: none;
    overflow: visible;

    .card-signup {
      margin: 0;

      .modal-header {
        padding-top: 0;
      }
    }
  }

  .modalTitle {
    color: $newDark;
    font-weight: $bold;
    line-height: 1;
    font-size: 30px;
  }

  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 0;
    padding-left: 24px;
  }

  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modalBody {
    padding: 10px 10px 10px 10px;
  }

  // Footer (for actions)
  .modal-footer {
    border-top: none;
    // padding: 24px;

    &.text-center {
      text-align: center;
    }

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;


      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }

  .modalBody+.modal-footer {
    padding-top: 0;
  }
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  .modal-dialog {
    margin-top: 130px;
  }

  .modal-header .close {
    color: $gray-light;

    &:hover,
    &:focus {
      opacity: 1;
    }

    i {
      font-size: 16px;
    }
  }
}

@media (max-width: $deviceBreak) {
  .modalDialog {
    margin: 0 5%;
  }
}

.status {
  display: flex;
  flex-wrap: nowrap;
  margin-top: -11px;

  .Active {
    background-color: #ddf0dd;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }

  .Inactive {
    background-color: #dbdbdb;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }
}

//Confirm model 

.confirmmodalDialog {
  max-width: 40% !important;
  width: 100%;
  height: auto;
  // margin: 50px;
  text-align: center;

  .modalContent {
    @include shadow-z-5();
    border-radius: $border-radius-large;
    border: none;
    overflow: visible;

    .card-signup {
      margin: 0;

      .modal-header {
        padding-top: 0;
      }
    }
  }

  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modalBody {
    padding: 10px 10px 10px 10px;
  }

  // Footer (for actions)
  .modal-footer {
    border-top: none;
    // padding: 24px;

    &.text-center {
      text-align: center;
    }

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;


      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }
  }

  .modalBody+.modal-footer {
    padding-top: 0;
  }
}

.datePicker {
  background-color: white;
  position: absolute;
  left: 0px;
  top: 82%;
  z-index: 9999;
}