@import "./../../Assets/scss/custom-variables.scss";

.productsWrapper {
  width: 100%;

  .title {
    font-size: 24px;
    color: $newDark;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }

  .searchIconWrapper {
    background: $btnBg;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;

    .searchIcon {
      height: 100%;
      padding: 8px;
    }
  }
}

.emptyImage {
  padding: 12px;
  background: #f2f3f5;
  border: 1.18881px solid #f2f3f5;
  border-radius: 6px;
  width: fit-content;

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 44px;
    color: #212721;
    opacity: 0.2;
  }
}

.tableStyle {
  td {
    padding: 20px 10px !important;
  }
}

.tableimg {
  width: 62px;
  height: 67px;
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tableimg1 {
  width: 140px;
  height: 99px;
  overflow: hidden;
  margin-top: 15px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ProducatImageWapper {
  display: inline-block;
  position: relative;
  img {
    height: 100px;
    width: 150px;
    margin-top: 10px;
  }
}

.quantityCol {
  min-width: 130px;
}

.quantityLabel {
  min-width: 12px;
}

.MuiButton-root {
  font-size: 20px !important;
  font-weight: 900 !important;
}
.checkBox {
  font-size: 17px;
  color: #131413;
}
.productTitle {
  font-size: 20px;
  color: $newDark;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}
.gotoList {
  list-style: none;
  li {
    color: #7a7a7a;
    font-size: 16px;
    line-height: 44px;
    font-weight: normal;
    position: relative;
    padding-left: 23px;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #c4c4c4;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      left: 0;
    }
  }
}
.productWrapper {
  display: flex;
  flex-direction: row;
}
.product {
  margin: 4px;
}
.productCard {
  position: relative;
  border: 0;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  padding: 0px 6px 0 12px;
  width: 100%;
  box-shadow: 0px 0px 10px 2px rgb(241, 241, 241);
  .featuredCard {
    background-color: #ffffff;
  }
}
.status {
  display: flex;
  flex-wrap: nowrap;
  margin-top: -11px;
  .Active {
    background-color: #ddf0dd;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }
  .Inactive {
    background-color: #dbdbdb;
    color: black;
    text-align: justify;
    margin: 10px 5px 25px 5px;
    padding: 2%;
    border-radius: 10%;
  }
}

.addCategory {
  display: inline-block;
  box-sizing: border-box;
  background-color: #f9f8f7;
  margin-left: 10px;
  margin-bottom: 10px;
  span {
    margin-left: 10px;
  }
  .closeIconWrapper {
    margin-left: 16px;
    margin-right: 10px;
  }
  .closeIcon {
    margin-bottom: 6px;
  }
}

.paragraphBackground {
  color: red;
}

.imageWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.imageWrapper:hover .image {
  opacity: 0.1;
}
.imageWrapper:hover .removeImage {
  display: block;
}
.removeImage {
  display: none;
  position: absolute;
  top: 35%;
  left: 45%;
  width: 30px;
  height: 30px;
  color: black;
}

.marginImage {
  margin-top: -14px;
}


.orderButton {
  font-size: 14px !important;
}

.inactiveProduct {
  filter: blur(0.5px); // Apply blur effect
  opacity: 0.5; // Set opacity to make it look inactive
  transition: all 0.3s ease; // Smooth transition for hover effects
}