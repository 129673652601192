@import "../../../Assets/scss/custom-variables.scss";

.searchIconWrapper {
    background: $btnBg;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .searchIcon {
      height: 100%;
      padding: 8px;
    }
  }

  .clearIconWrapper {
    background: $lightGray1;
    height: 34px;
    width: 34px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px;
    text-align: center;
  
    .clearIcon {
      height: 100%;
      padding: 8px;
    }
  }

  .positive {
    display: block;
    padding: 10px 0px;
    text-align: justify;
    background-color: #ddf0dd;
    border-radius: 10%;
    text-align: center;
    }
    
    .negative {
    display: block;
    padding: 10px;
    text-align: justify;
    background-color: $red-A100;
    text-align: center;
    border-radius: 10%;
    }
  